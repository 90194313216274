<template>
	<div class="route-instructions">

		<div class="finish-instructions" v-if="!editMode">
			<div>
				<p>Click <strong>Edit</strong> to change the order of the Artworks. If you are happy with your Artworks route, click <strong>Receive My Map</strong> to save and share your route.</p>
			</div>
			<div class="buttons">
				<button class="no-style" @click="enterEditMode">Edit</button>
				<router-link @click.native="sendToOracle" class="styled-button finished-button" to='/favourites/email-my-route'>Receive My Map</router-link>
			</div>
		</div>

		<div class="order-instructions" v-else>
			<p>Change the order of the Artworks using the arrows. Click <strong>Done</strong> to see the new order appear on the map.</p>
			<button class="styled-button" @click="exitEditMode" data-wt_params="WT.ihtml=Done">Done</button>
		</div>

	</div>
</template>

<script>
import {mapState} from "vuex";

export default {
	name: "RouteInstructions",
	computed: {
		...mapState({
			editMode: state => state.planRoute.editMode,
		}),
	},
	methods: {
		enterEditMode() {
			this.$store.commit("editMode", true);
		},
		exitEditMode() {
			this.$store.commit("editMode", false);
		},
    sendToOracle() {
      if(window.dcsMultiTrack) {
        window.dcsMultiTrack('WT.ihtml', 'Finished');
      }
    }
	}
}
</script>
