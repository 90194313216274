<template>
  <section class="remaining-events">

    <div class="container">

      <h2 v-if="title">{{ title }}</h2>

      <div class="event-card-container">
        <EventCard v-for="event in slicedEvents" :event="event" :key="event.id" />
      </div>

    </div>

		<LoadMore v-if="events.length > increment && currentIncrement < events.length" @clicked="loadMore" />
		
  </section>
</template>

<script>
import EventCard from "./EventCard";
import LoadMore from "../global/LoadMore";

export default {
  components: {LoadMore, EventCard},
  props: ['events', 'title'],
  name: 'Listing',
  data() {
    return {
      increment: 6,
      currentIncrement: 6
    }
  },
  computed: {
    computedIncrement() {
      if(this.events.length < this.currentIncrement) {
        return this.events.length;
      }

      return this.currentIncrement;
    },
    slicedEvents() {
      return this.events.slice(0, this.currentIncrement);
    }
  },
  methods: {
    loadMore() {
      this.currentIncrement += this.increment;
    }
  }
}
</script>

