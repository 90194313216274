<template>
  <div class="select-autocomplete-filter">

    <FilterContainer :label="heading" :filter-type="filterType" :group-type="`multi`">

      <div class="form-group">
        <div class="autocomplete-container">
          <input ref="input" type="text"
                 v-model="inputModel"
                 @keyup.down="focusOnList"
                 @keydown.enter.prevent=""
                 aria-describedby="neighbourhood-error"
                 placeholder="Start typing a neighbourhood (eg. Kensington, Waterfront, etc)"/>

          <div class="autocomplete-options" v-if="showOptions">
            <ul>
              <li v-for="autocompleteOption in filteredOptions" :key="autocompleteOption">
                <button ref="optionsList"
                        @keyup.down="moveDownList"
                        @keyup.up="moveUpList"
                        @keyup.enter.prevent="addToInput(autocompleteOption)"
                        type="button"
                        @click="addToInput(autocompleteOption)">{{ autocompleteOption }}
                </button>
              </li>
            </ul>
          </div>

        </div>
        <button type="button" @click="addItem">{{ buttonText }}</button>
      </div>

      <div id="neighbourhood-error" aria-live="assertive">
        <p v-show="error" class="error">{{ error }}</p>
      </div>

      <div class="pill-container">
        <div class="pill" v-for="selection in $store.state[stateScope][filterType]" :key="selection">{{ selection }}
          <button type="button" @click="removeItem(selection)"
                  :title="`Remove ${selection} from active neighbourhood filters`">&times;
          </button>
        </div>
      </div>

    </FilterContainer>

  </div>
</template>

<script>
import FilterContainer from "./FilterContainer";

export default {
  name: "SelectAutocompleteFilter",
  components: {FilterContainer},
  props: ['heading', 'buttonText', 'filterType', 'options', 'addDispatchAction', 'removeDispatchAction', 'stateScope'],
  data() {
    return {
      input: "",
      inputModel: "",
      error: "",
      clickedOption: false,
      highlightedOption: 0,
    }
  },
  watch: {
    inputModel() {
      this.error = '';
    }
  },
  computed: {
    selections() {
      return this.$store.state[this.stateScope][this.filterType];
    },
    showOptions() {
      return (this.filteredOptions.length > 0 && this.inputModel.length > 0 && !this.clickedOption);
    },
    filteredOptions() {
      return this.options.filter((item) => {
        return item.toLowerCase().includes(this.inputModel.toLowerCase());
      });
    },
  },
  methods: {
    addToInput(selection) {
      this.inputModel = selection;
      this.clickedOption = true;
      this.highlightedOption = 0;
      this.addItem();
      this.$refs.input.focus();
    },
    focusOnList() {
      if (this.$refs.optionsList.length > 0) {
        this.$refs.optionsList[this.highlightedOption].focus();
      }
    },
    moveUpList() {
      if (this.highlightedOption === 0) {
        return false;
      }

      this.highlightedOption--;
      this.focusOnList();
    },
    moveDownList() {

      if (this.highlightedOption === (this.$refs.optionsList.length - 1)) {
        return false;
      }

      this.highlightedOption++;
      this.focusOnList();
    },
    addItem() {

      // validate, does the input equal a borough option?
      if (this.options.includes(this.inputModel)) {

        this.$store.dispatch(this.addDispatchAction, {type: this.filterType, data: this.inputModel});

        if (window.dcsMultiTrack) {
          window.dcsMultiTrack('filter', this.inputModel, 'category', 'neighbourhood');
        }

        this.inputModel = "";
        this.$store.commit('boroughView', true);

      } else {

        this.error = 'Please enter a valid neighbourhood';

      }

      this.clickedOption = false;

    },
    removeItem(selection) {
      this.$store.dispatch(this.removeDispatchAction, {type: this.filterType, data: selection});
    }
  }
}
</script>