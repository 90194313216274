<template>
	<div :class="['blog-card', isFeatured ? 'featured' : '']">
		
		<div class="blog-image" v-if="imageURL">
			<img :src="imageURL" :alt="blog.alt_text" />
		</div>
		
		<div class="blog-details">
			
			<div class="blog-categories" v-if="blog.categories && blog.categories.length > 0">
				<span v-for="(category, n) in blog.categories" :key="category.name">{{ category.name }}{{ n < (blog.categories.length - 1) ? ',' : '' }} </span>
			</div>
			
			<div class="blog-details-text">
				<h2><a :href="`/artworxto-blog/${blog.slug}`">{{ blog.title }}</a></h2>
				<p class="excerpt" v-if="blog.excerpt">{{ blog.excerpt }}</p>
				
				<div class="author" v-if="blog.author"><span>Author: </span> {{ blog.author.name }}</div>
				<div class="date"><span>Date: </span> {{ formattedDate }}</div>
			</div>

		</div>
		
	</div>
</template>

<script>
export default {
	name: "BlogCard",
	props: ['blog', 'isFeatured'],
	computed: {
		imageURL() {
			
			if(!('images' in this.blog)) {
				return false;
			}
			
			if(!Object.keys(this.blog.images).length) {
				return false;
			}
			
			return this.isFeatured ? this.blog.images.medium.url : this.blog.images.thumb.url;
			
		},
		formattedDate() {
			return this.$moment(this.blog.publish_date).format('MMM Do, YYYY');
		}
	}
}
</script>