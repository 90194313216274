<template>
  <div class="artwork-view-button">
		<p>View Artworks:</p>
    <div class="states">
      <button @click="setView('map')" :class="[artworksView === 'map' ? 'active' : '', 'toggle-button']">
        <img :src="mapIcon" alt="" /> Map View
      </button>
      <button @click="setView('grid')" :class="[artworksView === 'grid' ? 'active' : '', 'toggle-button']">
        <img :src="gridIcon" alt="" /> Grid View
      </button>
    </div>
  </div>
</template>

<script>

import {mapActions, mapState} from 'vuex'

export default {
  name: "ArtworksViewToggle",
  data() {
    return {
      mapIcon: require('../../../assets/images/icon-map-inactive.svg'),
      gridIcon: require('../../../assets/images/icon-grid-inactive.svg')
    }
  },
  computed: {
    ...mapState({
			artworksView: state => state.artworks.artworksView
		})
  },
  methods: {
    ...mapActions({
      setView: 'setArtworksView'
    })
  }
}

</script>