<template>
  <div class="favourites-intro">

    <div class="container">

      <h1>My Favourites</h1>

      <div class="info-container">

        <div class="item">
          <img src="/images/icon-artworks.svg" alt=""/>
          <h2>Artworks</h2>
          <p>Favourite any artworks that you would like to reference again or visit. Then you can add your favourited artworks to a customized, detailed travel route.</p>
          <a href="#Artworks">See My Favourite Artworks</a>
        </div>

        <div class="item">
          <img src="/images/icon-events.svg" alt=""/>
          <h2>Events</h2>
          <p>Favourite any events that you would like to reference again or attend.</p>
          <a href="#Events">See My Favourite Free Events</a>
        </div>

        <div class="item">
          <img src="/images/icon-email.svg" alt=""/>
          <h2>Email Your Favourites</h2>
          <p>Please note that no personal information is stored on the site, so you must email yourself your favourite list in order to save your selections.</p>
          <a href="#EmailList">Email My Favourites</a>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "FavouritesIntro",
}
</script>