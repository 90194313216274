<template>
  <div class="favourite-button-wrapper">
    <FavouriteButton :item-id="itemId" :item-name="itemName" :favourites-group="favouritesGroup" />
  </div>
</template>

<script>
import FavouriteButton from "./FavouriteButton";

export default {
  name: "FavouriteButtonWrapper",
	props: {
		itemId: {
			default: 0
		},
		favouritesGroup: {
			type: String,
			default: 'artworks'
		},
    itemName: {
		  type: String,
		  default: ''
    }
	},
  components: {FavouriteButton},
  mounted() {
    this.$store.dispatch('setInitialFavourites');
  }
}
</script>
