<template>
	<div class="filters-section">
		
		<div class="blog-search-container">
			
			<SearchBar
				clear-dispatch-action="clearBlogSearch"
				results-dispatch-action="setSearchedBlogs"
				label="Search blogs by author, title, or content"
				endpoint="/api/blog/search"
				term-dispatch-action="setBlogSearchTerm" />
			
			<Sort />
		
		</div>
		
		<FiltersWrapper
			@reset="clearAll"
			:label="`Blog Filters`"
		>
			
			<MultiSelectFilter
				:options="blogCategories"
				dispatch-action="setActiveBlogCategories"
				:state-scope="stateScope"
				:filter-type="`activeBlogCategories`"
				:heading="`Categories`" />
			
			<SingleSelectFilter
				:options="months"
				dispatch-action="setActiveMonth"
				:state-scope="stateScope"
				:filter-type="`activeMonth`"
				:heading="`Published Month`" />
			
		</FiltersWrapper>
		
		<div class="blog-filter-message-container">
			
			<div class="filter-message-container">
				<p class="filter-message" role="status" aria-live="polite" aria-atomic="true">
					Showing {{ filteredBlogs.length }} blogs {{ activeFilters }}</p>
				<button class="no-style clear-button" @click="clearAll()">Reset All</button>
			</div>
			
		</div>
	
	</div>
</template>

<script>
import SingleSelectFilter from "../../global/filters/SingleSelectFilter";
import MultiSelectFilter from "../../global/filters/MultiSelectFilter";
import {mapState} from "vuex";
import Sort from "./Sort";
import FiltersWrapper from "../../global/filters/FiltersWrapper";
import SearchBar from "../../global/filters/SearchBar";

export default {
	components: {SearchBar, FiltersWrapper, Sort, MultiSelectFilter, SingleSelectFilter},
	name: 'BlogFilters',
	data() {
		return {
			accordionState: false,
			stateScope: 'blog',
			months: ["January","February","March","April","May","June","July",
											"August","September","October","November","December"]
		}
	},
	mounted() {
		this.getCategories();
	},
	computed: {
		...mapState({
			blogCategories: state => state.blog.blogCategories,
			filteredBlogs: state => state.blog.filteredBlogs
		}),
		accordionSign() {
			return this.accordionState ? '–' : '+';
		},
		activeFilters() {
			
			let text = '';
			
			for (const [key, value] of Object.entries(this.$store.getters.getActiveBlogFilters)) {
				
				if(value.length > 0) {
					
					if((typeof value) == 'object') {
						value.forEach((item) => {
							text += (item + ', ');
						})
					} else if((typeof value) == 'string') {
						text += (value + ', ');
					}
					
				}
				
			}
			
			if(text.length) {
				text = `in ${text}`.trim().slice(0, -1);
			}
			
			return text;
		}
	},
	methods: {
		getCategories() {
			
			axios
				.get('/api/blog/categories')
				.then(response => {
					
					if(response.data.length) {
						this.$store.dispatch('setBlogCategories', response.data)
					}
					
				});
			
		},
		clearAll() {
			this.$store.dispatch("clearAllBlogFilters");
		},
		toggleAccordion() {
			this.accordionState = !this.accordionState;
		},
		resetFilters() {
			this.$store.dispatch("clearBlogFilters");
		},
	}
}
</script>

<style lang="scss">
.view-results-button {
	font-size: 18px;
	font-weight: 900;
	letter-spacing: 0.2px;
	text-align: center;
	padding: 10px;
	margin-left: 10px;
	margin-bottom: 10px;
	&:after {
		background: black;
		z-index: -1;
	}
}
.filter-buttons {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
}
</style>
