<template>
	<div class="route-directions">
		
		<div class="leg" v-for="(leg, n) in legs" :key="n">
			
			<div class="order-number"><span>{{ n + 1 }}</span></div>
			
			<div>
				
				<ul>
					<li v-for="(step, i) in leg.steps" :key="i">
						<p v-html="step.instructions"></p>
						<div class="details"><span>{{ step.distance.text }} ({{ step.duration.text }})</span></div>
					</li>
				</ul>
				
				<SelectCard :artwork="artworkSelections[n]" />
				
				<div class="total-leg">{{ leg.distance.text }} ({{ leg.duration.text }})</div>
				
			</div>
			
		</div>
	
	</div>
</template>

<script>
import {mapState} from "vuex";
import SelectCard from "../artworks/cards/SelectCard";

export default {
	name: "RouteDirections",
	components: {SelectCard},
	computed: {
		...mapState({
			artworkSelections: state => state.planRoute.artworkSelections,
			legs: state => state.planRoute.legs
		}),
	},
}
</script>