<template>
  <section v-if="parsedArtworks.length" class="item-carousel">
    <div class="container">

      <div class="carousel-control">

        <div class="heading-group">
          <h2 v-if="heading" id="carousel-heading">{{ heading }}</h2>

          <div class="past-current-toggle">
            <button aria-label="Show Current Artworks" :class="[ !showPast ? 'active' : '' ]"
                    @click="setShowPast(false)">Show Current
            </button>
            <button aria-label="Show Past Artworks" :class="[ showPast ? 'active' : '' ]" @click="setShowPast(true)">
              Show Past
            </button>
          </div>

        </div>

        <div :class="['controls', filteredArtworks.length < 4 ? 'hide-on-desktop' : '']"
             v-if="filteredArtworks.length > 1">
          <button @click.prevent="slidePrev" v-show="!isPrevDisabled" aria-label="Slide Previous">
            <img :src="require('../../assets/images/arrows/Left.svg')" alt="">
          </button>
          <p class="item-count">{{ filteredArtworks.length }} artworks</p>
          <button @click.prevent="slideNext" v-show="!isNextDisabled" aria-label="Slide Next">
            <img :src="require('../../assets/images/arrows/Right.svg')" alt="">
          </button>
        </div>

      </div>

      <hooper
          :settings='{
          transition: 600,
          wheelControl: false,
          shortDrag: false,
          infiniteScroll: false,
          trimWhiteSpace: true,
          breakpoints: {
            1024: {
              itemsToShow: 3
            },
            764: {
              itemsToShow: 2
            },
            400: {
              itemsToShow: 1
            },
          }
        }'
          ref="carousel"
          @slide="updateAria"
      >

        <slide v-for="artwork in filteredArtworks" :key="artwork.id">
          <Card :artwork="artwork" :hide-carousel="true"/>
        </slide>

      </hooper>

      <div v-if="filteredArtworks.length === 0" class="no-items">
        <p>There are no {{ showPast ? 'past' : 'current' }} artworks right now.</p>
      </div>

    </div>
  </section>
</template>

<script>
import {Hooper, Slide, Pagination as HooperPagination} from 'hooper';
import 'hooper/dist/hooper.css';
import Card from "./cards/Card";
import {slider} from "../../mixins/slider";

export default {
  name: 'ArtworkCarousel',
  props: ['artworks', 'heading'],
  mixins: [slider],
  components: {Card, Hooper, Slide, HooperPagination},
  data() {
    return {
      parsedArtworks: [],
    }
  },
  mounted() {
    this.parsedArtworks = JSON.parse(this.artworks);
    this.updateAria();
  },
  computed: {
    filteredArtworks() {
      return this.parsedArtworks.filter((artwork) => {
        return artwork.is_current === !this.showPast;
      });
    }
  }
}
</script>

<style lang="scss">
@import "~hooper/dist/hooper.css";

.hooper {
  height: auto;
}
</style>