<template>
  <div class="date-filter">

    <FilterContainer
        label="Date Selection"
        filter-type="date"
    >

      <DatePicker
          :value="dates"
          :select-attribute="selectAttribute"
          :attributes="attributes"
          :min-date="allowPast ? null : new Date()"
          @dayclick="onDayClick"
      />

    </FilterContainer>

  </div>
</template>

<script>

import FilterContainer from "./FilterContainer";
import {mapState} from "vuex";
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: "DateFilter",
  components: {FilterContainer, DatePicker},
  props: {
    allowPast: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      days: [],
      selectAttribute: {
        highlight: {
          backgroundColor: 'white',
          borderColor: 'red',
          borderWidth: '3px',
          borderStyle: 'solid',
          width: '2.4rem',
          height: '2.4rem',
        },
        contentStyle: {
          color: 'grey',
        }
      },
    }
  },
  computed: {
    ...mapState({
      events: state => state.events.initialEvents,
      selections: state => state.events.dates
    }),
    dates() {
      return this.days.map(day => day.date);
    },

    attributes() {

      // get array of dates from events
      const dates = this.events.map(event => {
        return event.sessions.map(date => {
          return this.getDates(new Date(date.start_datetime), new Date(date.end_datetime));
        });
      }).flat(2);

      const dots = [{
        key: 'hasEvent',
        dot: {
          style: {
            backgroundColor: 'black',
          },
        },
        dates
      }];

      const selections = this.selections.map(day => {

        return {
          highlight: {
            color: 'black',
            fillMode: 'outline',
            class: 'date-selected'
          },
          dates: new Date(day),
        }

      });

      return [...dots, ...selections];
    },
  },
  watch: {
    days() {
      this.$store.dispatch('setDates', this.days.map(date => date.date));
    }
  },
  methods: {
    onDayClick(day) {

      if (!day.isDisabled) {

        const idx = this.days.findIndex(d => d.id === day.id);

        if (idx >= 0) {
          this.days.splice(idx, 1);
        } else {
          this.days.push({
            id: day.id,
            date: day.date,
          });
        }

      }

    },
    getDates(startDate, endDate) {

      const dates = []
      let currentDate = startDate

      const addDays = function (days) {
        const date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)
        return date
      }

      while (currentDate <= endDate) {
        dates.push(currentDate)
        currentDate = addDays.call(currentDate, 1)
      }

      return dates
    }
  },
}
</script>