<template>
  <div class="map">

    <GmapMap
        ref="mapRef"
        :center="center"
        :zoom="zoom"
        :options="{
            mapTypeControl: false,
            fullscreenControl: true,
            styles: mapStyles
          }"
    >

      <!-- Fullscreen info window -->
      <GmapInfoWindow
          :options="{
						maxWidth: 300,
						pixelOffset: { width: 0, height: -35 }
					}"
          :position="infoWindowPos"
          :opened="isFullScreen && activeArtwork !== null"
          @click="closeWindow"
      >
        <InfoWindowHTML/>
      </GmapInfoWindow>

      <!-- Artwork Listing -->
      <GmapInfoWindow
          :options="{
						maxWidth: 400,
						pixelOffset: { width: 0, height: -20 }
					}"
          :position="infoWindowPos"
          v-if="clusterArtworkList.length > 0"
          :opened="clusterArtworkList.length > 0"
          @closeclick="closeClusterWindow"
      >
        <ListingInfoWindow/>
      </GmapInfoWindow>

      <GmapCluster
          :zoomOnClick="false"
          :minimumClusterSize=5
          :styles=clusters
          @click="handleClusterClick"
      >
        <GmapMarker
            ref="markerRef"
            v-if="google"
            v-for="art in filteredArtworks"
            :key="art.id"
            :position="{lat: art.latitude, lng: art.longitude}"
            :clickable="true"
            :title="getMarkerTitle(art)"
            :icon="getMarkerIcon(art.artwork_type, art.id)"
            :visible="isVisibleMarker(art)"
            @click="handleMarkerClick(art)"
        />
      </GmapCluster>

      <GmapMarker
          ref="activeMarker"
          v-if="activeArtwork && google"
          :position="{lat: activeArtwork.latitude, lng: activeArtwork.longitude}"
          :clickable="true"
          :title="activeArtwork.name"
          :animation=4
          :icon="getPinType(activeArtwork, true)"
          :zIndex="10000"
          @click="handleMarkerClick(activeArtwork)"
      />

      <GmapMarker
          ref="homeMarker"
          v-if="geolocated"
          :position="center"
          title="Geolocation Marker"
          :clickable="false"
          :icon="require('../../assets/images/bluecircle.png')"
          :zIndex="11000"
      />

      <button class="no-style geolocation-button" @click="geolocate">
        <img :src="require('../../assets/images/geolocate.png')" alt="Geolocation icon"/>
      </button>

      <div class="borough-button">
        <p>Neighbourhood View</p>
        <div class="states">
          <button @click="toggleBoroughFilter(true)" :class="[boroughView ? 'active' : '']"
                  aria-label="Turn borough layer on">ON
          </button>
          <button @click="toggleBoroughFilter(false)" :class="[!boroughView ? 'active' : '']"
                  aria-label="Turn borough layer off">OFF
          </button>
        </div>
      </div>

    </GmapMap>

    <MapInfoWindow ref="infoWindow" @close="closeWindow" :pin="getPin(activeArtwork.artwork_type)"
                   v-if="!isFullScreen && activeArtwork" :artwork="activeArtwork"/>

    <div id="GeolocationError" v-if="showGeolocationError">{{ geolocationError }}</div>

  </div>

</template>

<script>
import MapInfoWindow from "./MapInfoWindow";

import GmapCluster from "vue2-google-maps/dist/components/cluster";

import geojson from "../../assets/data/neighbourhoods.json";
import {gmapApi} from 'vue2-google-maps'

import {pins} from '../../mixins/pins';

import {mapState} from 'vuex'
import InfoWindowHTML from "./cards/InfoWindowHTML";
import ListingInfoWindow from "./ListingInfoWindow";

export default {
  name: "Map",
  components: {ListingInfoWindow, InfoWindowHTML, MapInfoWindow, GmapCluster},
  mixins: [pins],
  data() {
    return {
      clusters: [
        {
          url: require('../../assets/images/clusters/m1.png'),
          height: 36,
          width: 36,
          textColor: "white"
        },
        {
          url: require('../../assets/images/clusters/m2.png'),
          height: 49,
          width: 49,
          textColor: "white"
        },
        {
          url: require('../../assets/images/clusters/m3.png'),
          height: 58,
          width: 58,
          textColor: "white"
        },
        {
          url: require('../../assets/images/clusters/m4.png'),
          height: 78,
          width: 78,
          textColor: "white"
        },
        {
          url: require('../../assets/images/clusters/m5.png'),
          height: 94,
          width: 94,
          textColor: "white"
        }
      ],
      center: {
        lat: 43.687295,
        lng: -79.420690
      },
      zoom: 11,
      infoWinOpen: false,
      infoContent: '',
      mapInfoContent: '',
      showGeolocationError: false,
      geolocated: false,
      geolocationError: '',
      infoWindowPos: {
        lat: 43.687295,
        lng: -79.420690
      },
      mapStyles: [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#000000"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#bdbdbd"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "poi.business",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#c9c9c9"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e3e3e3"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#bdbdbd"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#1e99ae"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        }
      ],
      boroughFilterVisible: false,
      isFullScreen: false
    }
  },
  computed: {
    ...mapState({
      activeArtwork: state => state.artworks.activeArtwork,
      filteredArtworks: state => state.artworks.filteredArtworks,
      boroughs: state => state.artworks.boroughs,
      boroughView: state => state.artworks.boroughView,
      clusterArtworkList: state => state.artworks.clusterArtworkList,
    }),
    google: gmapApi
  },
  watch: {
    boroughView() {
      this.$refs.mapRef.$mapPromise.then((map) => {

        map.data.revertStyle();

        map.data.setStyle({
          fillColor: 'lightgrey',
          strokeWeight: 1,
          visible: this.boroughView
        });

      });
    },
    boroughs() {

      this.$refs.mapRef.$mapPromise.then((map) => {

        map.data.forEach((feature) => {

          let fillColor;
          let strokeWeight;

          let borough = feature.getProperty('AREA_NAME');

          if (this.boroughs.includes(borough)) {

            fillColor = 'forestgreen';
            strokeWeight = 3;

          } else {

            fillColor = 'lightgrey';
            strokeWeight = 1;

          }

          map.data.overrideStyle(feature, {fillColor, strokeWeight});

        });

      });

    },
    activeArtwork() {

      if (this.activeArtwork) {

        this.zoom = 16;

        this.$refs.mapRef.$mapPromise.then((map) => {
          let latLong = {lat: this.activeArtwork.latitude, lng: this.activeArtwork.longitude};
          map.panTo(latLong);
          this.infoWindowPos = latLong;
        });

      }

    }
  },
  mounted() {
    this.createBoroughsLayer();
    this.createFullScreenEvents();
  },
  methods: {

    getMarkerTitle(art) {

      const name = art.name ? art.name : 'Untitled Artwork';
      const artist = art.artist ? art.artist : 'Unknown Artist';

      return `${name} by ${artist}`;
    },

    createFullScreenEvents() {
      const eventNames = [
        'fullscreenchange',
        'webkitfullscreenchange',
        'mozfullscreenchange'
      ];

      eventNames.map(e => document.addEventListener(e, (event) => {
        this.isFullScreen = document['fullScreen'] ||
            document['mozFullScreen'] || document['webkitIsFullScreen'];
      }));
    },

    isVisibleMarker(art) {

      if (this.activeArtwork) {
        return this.activeArtwork.id !== art.id;
      }

      return true;
    },

    createBoroughsLayer() {
      this.$refs.mapRef.$mapPromise.then((map) => {

        map.data.addGeoJson(geojson);

        map.data.setStyle({
          fillColor: 'lightgrey',
          strokeWeight: 1,
          visible: false
        });

        // Add the borough data to the Vuex store
        map.data.forEach((feature) => {
          this.populateBoroughState(feature);
        });

        map.data.addListener('click', event => {
          this.handleBoroughClick(event, map);
        });

      });
    },

    populateBoroughState(feature) {

      const type = feature.getGeometry().getType();

      let poly = [];

      if (type == 'MultiPolygon') {

        const coords = feature.getGeometry().getArray();

        coords.forEach((coord) => {

          const coords = coord.getAt(0).getArray();

          poly.push(new google.maps.Polygon({
            paths: coords
          }))

        });

      } else {

        poly = [new this.google.maps.Polygon({
          paths: feature.getGeometry().getAt(0).getArray()
        })];

      }

      const data = {
        name: feature.getProperty('AREA_NAME'), type, poly
      };

      this.$store.dispatch('populateBoroughState', data);

    },

    geolocate() {
      if (navigator.geolocation) {

        navigator.geolocation.getCurrentPosition((position) => {
              this.center = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              };
              this.geolocated = true;
            },
            () => {
              this.showGeolocationError = true;
              this.geolocationError = "The Geolocation service failed.";
              console.error(this.geolocationError);
            });

      } else {
        // Browser doesn't support Geolocation
        this.showGeolocationError = true;
        this.geolocationError = "Your browser doesn't support geolocation.";
        console.error(this.geolocationError);
      }

    },

    closeWindow() {
      this.$store.dispatch('setActiveArtwork', null);
    },

    closeClusterWindow() {
      this.$store.dispatch('setClusterArtworkList', []);
    },

    handleMarkerClick(art) {

      if (window.dcsMultiTrack) {
        window.dcsMultiTrack('WT.dl', '21', 'WT.event_name', this.getMarkerTitle(art), 'category', art.artwork_type);
      }

      this.$store.dispatch('setActiveArtwork', art);

    },

    handleClusterClick(e) {

      let clusterCenter = e.getCenter();

      // zoom in further unless past the 16th level
      this.$refs.mapRef.$mapPromise.then((map) => {
        if (map.getZoom() < 17) {
          map.fitBounds(e.getBounds());
        } else {
          let markers = e.getMarkers();
          let ids = markers.map(marker => marker.icon.id);
          this.infoWindowPos = clusterCenter;
          this.$store.dispatch('setClusterArtworkList', ids);
        }
      });

    },

    maybeBounce(art) {
      return (this.activeArtwork && this.activeArtwork.id === art.id) ? 4 : 0;
    },

    resetMap() {
      this.$store.dispatch('clearAll');
      this.reCentreMap();
    },

    reCentreMap() {
      this.zoom = 11;
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.panTo(this.center);
      });
    },

    toggleBoroughFilter(state) {

      this.$store.commit('boroughView', state);

      if (!state) {
        this.$store.dispatch('removeAllMultiFilter', 'boroughs');
      }

    },

    handleBoroughClick(event) {

      let borough = event.feature.getProperty('AREA_NAME');

      if (!this.boroughs.includes(borough)) {

        this.$store.dispatch('addMultiFilter', {type: 'boroughs', data: borough});

      } else {

        this.$store.dispatch('removeMultiFilter', {type: 'boroughs', data: borough});

      }

    },

  }
}
</script>
