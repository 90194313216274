const state = {
	
	initialTours: [],
	filteredTours: [],
	
	options: {
		neighbourhoods: []
	},
	
	neighbourhoods: [],
	params: []
	
};

const mutations = {
	initialTours(state, data) {
		return state.initialTours = data;
	},
	filteredTours(state, data) {
		return state.filteredTours = data;
	},
	featuredTour(state, data) {
		return state.featuredTour = data;
	},
	neighbourhoods(state, data) {
		return state.neighbourhoods = data;
	},
	options(state, data) {
		return state.options = data;
	}
};

const getters = {
	
	getTourFilters(state) {
		let {neighbourhoods, dates} = state;
		return {neighbourhoods, dates};
	},
	
	getActiveTourFiltersBool(state) {
		return (state.neighbourhoods.length > 0);
	},
	
	getNeighbourhoodOptions(state) {
		return state.options.neighbourhoods;
	},
	
	getActiveTourFilters(state) {
		let {neighbourhoods} = state;
		return {neighbourhoods};
	}
	
};

const actions = {
	
	setInitialTours({commit, dispatch}, tours) {
		commit("initialTours", tours);
	},
	
	filterTours({commit, state, dispatch}) {
		
		let filteredTours = [...state.initialTours];
		
		if(state.neighbourhoods.length > 0) {
			filteredTours = filteredTours.filter((tour) => {
				return (tour.neighbourhood && state.neighbourhoods.includes(tour.neighbourhood.name));
			});
		}
		
		commit("filteredTours", filteredTours);
		// dispatch("setToursUrl");
		
	},
	
	setNeighbourhoodFilter({commit, state, dispatch}, payload) {
		let {type, data} = payload;
		
		commit(type, [...state[type], data]);
		
		dispatch('filterTours');
	},
	
	removeNeighbourhoodFilter({commit, state, dispatch}, payload) {
		
		let {type, data} = payload;
		
		let filter = [...state[type]];
		
		const index = filter.indexOf(data);
		
		if (index > -1) {
			filter.splice(index, 1);
		}
		
		commit(type, filter);
		
		dispatch('filterTours');
		
	},
	
	setNeighbourhoodOptions({commit, state}, payload) {
		
		let {type, items} = payload;
		let options = {...state.options};
		
		options[type] = items;
		
		commit('options', options);
		
	},
	
	setToursUrl({getters}) {
		
		let params = '';
		
		if (getters.getActiveTourFiltersBool) {
			
			let filters = getters.getTourFilters;
			
			params += '?';
			
			for (const [key, value] of Object.entries(filters)) {
				
				if (value.length > 0) {
					
					if ((typeof value) == 'object') {
						
						value.forEach((item) => {
							params += `${key}[]=${encodeURIComponent(item)}&`;
						})
						
					} else if ((typeof value) == 'string') {
						params += `${key}=${encodeURIComponent(value)}&`;
					}
					
				}
				
			}
			
			params = params.replace(/&$/, '');
			
		}
		
		window.history.replaceState({}, "ArtworxTO Tours", params);
		
	},
	
	setInitialTourFilters({commit, dispatch, state}, params) {
		
		for (const [key, value] of Object.entries(params)) {
			
			if (key in state) {
				commit(key, value);
			}
			
		}
		
		dispatch('filterTours');
		
	},
	
	
	clearTourFilters({commit, dispatch}) {
		commit('neighbourhoods', []);
		dispatch('filterTours');
	}
	
};

export default {
	state,
	mutations,
	getters,
	actions
}