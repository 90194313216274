<template>
  <div class="translation-selection">
    <label for="tl">Use the dropdown below to translate this site to 51 languages</label>
    <select v-model="translation" @change="translate" id="tl" name="tl">
      <option id="languageStart" selected value="">Select a Language</option>
      <option value="af">Afrikaans</option>
      <option value="sq">shqip</option>
      <option dir="ltr" value="ar">العربية</option>
      <option value="be">Беларуская</option>
      <option value="bg">български</option>
      <option value="ca">català</option>
      <option value="zh-CN">中文(简体)</option>
      <option value="zh-TW">中文(繁體)</option>
      <option value="hr">Hrvatska</option>
      <option value="cs">česky</option>
      <option value="da">Dansk</option>
      <option value="nl">Nederlands</option>
      <option value="et">Eesti</option>
      <option value="tl">Filipino</option>
      <option value="fi">suomi</option>
      <option value="fr">Français</option>
      <option value="gl">Galego</option>
      <option value="de">Deutsch</option>
      <option value="el">Ελληνικά</option>
      <option dir="ltr" value="iw">עברית</option>
      <option value="hi">हिन्दी</option>
      <option value="hu">magyar</option>
      <option value="is">Íslenska</option>
      <option value="id">Indonesia</option>
      <option value="ga">Gaeilge</option>
      <option value="it">Italiano</option>
      <option value="ja">日本語</option>
      <option value="ko">한국어</option>
      <option value="lv">latviešu valoda</option>
      <option value="lt">Lietuvių</option>
      <option value="mk">Македонски</option>
      <option value="ms">Bahasa Melayu</option>
      <option value="mt">Malti</option>
      <option value="no">norsk</option>
      <option dir="ltr" value="fa">فارسی</option>
      <option value="pl">Polski</option>
      <option value="pt">Português</option>
      <option value="ro">Română</option>
      <option value="ru">Русский</option>
      <option value="sr">српски</option>
      <option value="sk">slovenčina</option>
      <option value="sl">slovenščina</option>
      <option value="es">Español</option>
      <option value="sw">Kiswahili</option>
      <option value="sv">Svenska</option>
      <option value="th">ไทย</option>
      <option value="tr">Türkçe</option>
      <option value="uk">українська</option>
      <option value="vi">Tiếng Việt</option>
      <option value="cy">Cymraeg</option>
      <option dir="ltr" value="yi">ייִדיש</option>
    </select>
  </div>
</template>

<script>
  export default {
    name: "Translate",
    data() {
      return {
        translation: ''
      }
    },
    methods: {
     translate() {
        window.location = `https://translate.google.com/translate?u=${encodeURIComponent(window.location.href)}&sl=en&tl=${this.translation}&hl=en&ie=UTF-8`;
     }
    }
  }
</script>