<template>
  <button class="no-style clear-button" @click="clearFilter" :aria-label="`Clear the ${filter} Filter`"><slot>Clear Filter</slot></button>
</template>

<script>
  export default {
    name: "ClearButton",
    props: ['groupType', 'filter'],
    methods: {
      clearFilter() {
        if(this.groupType === 'single') {
          this.$store.dispatch('setSingleFilter', {type: this.filter, data: ""})
        } else if(this.groupType === 'multi') {
          this.$store.dispatch('removeAllMultiFilter', this.filter);
        }
      }
    }
  }
</script>