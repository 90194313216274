<template>
  <div class="map-listing favourites-listing">
			<router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'MyFavouritesPage',
	props: ['artworks', 'events'],
	mounted() {
		this.$store.dispatch('setInitialFavourites');
		this.$store.dispatch('setInitialArtworks', this.filteredArtworks());
		this.$store.dispatch('setInitialEvents', this.filteredEvents());
		this.$store.dispatch('filterArtworks');
	},
	methods: {
		filteredArtworks() {
			return JSON.parse(this.artworks).filter(artwork => {
				return this.$store.state.favourites.artworks.includes(artwork.id.toString());
			});
		},
		filteredEvents() {
			return JSON.parse(this.events).filter(event => {
				return this.$store.state.favourites.events.includes(event.id.toString());
			});
		}
	}
}
</script>
