<template>
  <div class="favourite-button-container">

    <button class="no-style favourite-button remove-from-favourite"
            @click="removeFromFavourites"
            v-if="isFavourite"
            data-wt_track="no"
            :aria-label="`Remove ${itemName} from My Favourites`">
			<img :src="hearts.filled" alt="" /> <span>Remove from My Favourites</span>
		</button>
		
    <button class="no-style favourite-button add-to-favourite"
            @click="addToFavourites"
            data-wt_track="no"
            :aria-label="`Save ${itemName} to My Favourites`"
            v-else>
			<img :src="hearts.empty" alt="" /> <span>Save to My Favourites</span>
		</button>

    <div id="favourite-status" class="sr-only" role="status" aria-live="polite" aria-atomic="true">{{ status }}</div>

  </div>
</template>

<script>

import { hearts } from "../../mixins/hearts"

export default {
  name: 'FavouriteButton',
  props: {
    itemId: {
      default: null
    },
    itemName: {
      type: String,
      default: ''
    },
		favouritesGroup: {
    	type: String,
			default: 'artworks' // or events
		}
  },
	mixins: [hearts],
  data() {
    return {
      status: ''
    }
  },
  computed: {
    isFavourite() {
    	if(!this.itemId) {
    		return false;
			}
    	
      return this.$store.state.favourites[this.favouritesGroup].includes(this.itemId.toString());
    }
  },
  methods: {
    updateStatus(status) {
      this.status = status;
    },
    removeFromFavourites() {
      this.$store.dispatch('removeFavourite', {id: this.itemId, type: this.favouritesGroup});
      this.updateStatus(`${this.itemName} is removed from My Favourites` );

      if(window.dcsMultiTrack) {
        window.dcsMultiTrack('WT.event_name', this.itemName, 'WT.ihtml', 'Remove from My Favourites');
      }

    },
    addToFavourites() {
      this.$store.dispatch('addFavourite', {id: this.itemId, type: this.favouritesGroup});
      this.$store.dispatch('maybeShowFavouriteOnboarding');
      this.updateStatus(`${this.itemName} is saved to My Favourites` );

      if(window.dcsMultiTrack) {
        window.dcsMultiTrack('WT.event_name', this.itemName, 'WT.ihtml', 'Add to My Favourites');
      }

    }
  }
}
</script>

