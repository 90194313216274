<template>

  <section id="Artworks" class="artworks-container">

    <div class="container map-intro">
      <h2>Artworks Map</h2>
      <div>
        <div>
          <p>Use the Interactive ArtworxTO map in order to explore Toronto's extensive collection of public art. Simply
            click on a pin and view the details for that particular work. Use the filters to sort by artist, artwork
            name, type, neighbourhood or City program.</p>
          <p><strong>Get out and discover Toronto's public art today!</strong></p>
        </div>

        <div class="plan-route-cta">
          <h3>Plan a Custom Route</h3>
          <p>You can plan a custom route to your favourited artworks! Add artworks to your favourites using the heart
            icon <img src="/images/icon-heart-empty.svg" alt=""/></p>
          <a href="/favourites" class="button styled-button small-button reversed"
             data-wt_params="WT.ihtml=See My Favourite Artworks">See My Favourite Artworks</a>
        </div>
      </div>
    </div>

    <div class="home-filters-section">

      <div class="container">


          <div class="map-search-container">

            <SearchBar
                clear-dispatch-action="clearSearch"
                results-dispatch-action="setSearchedArtworks"
                label="Search artworks by title, artist, medium or year"
                endpoint="/api/search/artworks"
                term-dispatch-action="setSearchTerm"/>

            <ArtworksViewToggle/>

          </div>

        <FiltersWrapper
            label="Filter Artworks"
        >

          <Filters/>

        </FiltersWrapper>

        <div id="ActiveFilters" class="active-filters">

          <div class="filter-message-container">
            <p class="filter-message" role="status" aria-live="polite" aria-atomic="true">Showing
              <b>{{ filteredArtworks.length }} results</b> {{ activeFilters }}</p>
            <button class="no-style clear-button" @click="clearAll()">Reset All</button>
          </div>

        </div>

      </div>

    </div>

    <div id="map-container" ref="map-container" style="padding-top: 108px; margin-top: -108px;">
      <Map v-show="artworksView === 'map'"/>
      <List v-if="artworksView === 'grid'"/>
      <Loading v-if="loading"/>
    </div>

  </section>

</template>

<script>
import List from "./List";
import SearchBar from "../global/filters/SearchBar";
import Filters from "./filters/Filters";

import {pins} from '../../mixins/pins';

import {mapState} from 'vuex'
import Map from "./Map";
import ArtworksViewToggle from "./buttons/ArtworksViewToggle";
import Loading from "../global/Loading"
import FiltersWrapper from "../global/filters/FiltersWrapper";

export default {
  name: "Artworks",
  props: ['artworks', 'params'],
  components: {FiltersWrapper, Loading, ArtworksViewToggle, Map, Filters, List, SearchBar},
  mixins: [pins],
  data() {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState({
      filteredArtworks: state => state.artworks.filteredArtworks,
      initialArtworks: state => state.artworks.initialArtworks,
      artworksView: state => state.artworks.artworksView,
    }),

    activeFilters() {

      let text = '';

      for (const [key, value] of Object.entries(this.$store.getters.getFilters)) {

        if (value.length > 0) {

          if ((typeof value) == 'object') {
            value.forEach((item) => {
              text += (item + ', ');
            })
          } else if ((typeof value) == 'string') {
            text += (value + ', ');
          }

        }

      }

      if (text.length) {
        text = `in ${text}`.trim().slice(0, -1);
      }

      return text;
    }
  },
  mounted() {

    let getArtworks = async () => {

      const artworks = await fetch('/api/artworks')

      if (artworks.ok) {

        let artworksArr = await artworks.json()
        await this.$store.dispatch('setInitialFavourites');
        await this.$store.dispatch('setInitialArtworks', artworksArr);
        await this.$store.dispatch('setInitialFilters', JSON.parse(this.params));
        this.loading = false;

      } else {
        console.error("Network error. Couldn't fetch artworks.")
      }

    }

    getArtworks();

  },
  methods: {
    toggleArtworkView(view) {
      this.$store.dispatch('setArtworksView', view);
    },
    clearAll() {
      this.$store.dispatch("clearAll");
    }
  }
}
</script>