<template>
  <div class="filter-container map-filters">
			
			<SelectAutocompleteFilter
				:options="getOptions('boroughs')"
				:filter-type="`boroughs`"
				add-dispatch-action="addMultiFilter"
        remove-dispatch-action="removeMultiFilter"
				state-scope="artworks"
				:heading="`Search by neighbourhood`"
				:button-text="`Add Area`" />

    <div class="filter-group">

      <MultiSelectFilter
          :options="getOptions('artworkTypes')"
          :filter-type="`artworkTypes`"
          dispatch-action="setMultiFilter"
          state-scope="artworks"
          :show-pins="true"
          :heading="`Artwork Types`" />

      <MultiSelectFilter
          :options="getOptions('programs')"
          dispatch-action="setMultiFilter"
          state-scope="artworks"
          :filter-type="`programs`"
          :heading="`City Programs`" />

      <MultiSelectFilter
          :options="getOptions('hubs')"
          dispatch-action="setMultiFilter"
          state-scope="artworks"
          :filter-type="`hubs`"
          :heading="`Community Cultural Hubs`" />

    </div>

  </div>
</template>

<script>
  import SingleSelectFilter from "../../global/filters/SingleSelectFilter";
  import MultiSelectFilter from "../../global/filters/MultiSelectFilter";
  import {mapState} from "vuex";
	import ClearButton from "../buttons/ClearButton";
	import FiltersWrapper from "../../global/filters/FiltersWrapper";
	import SelectAutocompleteFilter from "../../global/filters/SelectAutocompleteFilter";

  export default {
    components: {SelectAutocompleteFilter, FiltersWrapper, ClearButton, MultiSelectFilter, SingleSelectFilter},
    name: 'MapFilters',
    mounted() {
      this.setPrograms();
      this.setHubs();
    },
    computed: {
      ...mapState({
				options: state => state.artworks.options
			})
    },
    methods: {
      setPrograms() {
        axios
            .get('/api/artworks/programs')
            .then(response => {

              if (response.data.length) {
                this.$store.dispatch('setProgramState', response.data);
              }

            });
      },
      setHubs() {
        axios
            .get('/api/hubs')
            .then(response => {

              if (response.data.length) {
                this.$store.dispatch('setHubState', response.data);
              }

            });
      },
      getOptions(type) {
        return this.options[type];
      },
      resetFilters() {
        this.$store.dispatch("clearFilters");
      },
      getHubs() {

      }
    }
  }
</script>

<style lang="scss">
  .view-results-button {
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0.2px;
    text-align: center;
    padding: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    &:after {
      background: black;
      z-index: -1;
    }
  }
  .filter-buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
</style>
