<template>
  <div :class="['artwork-card', cardClass]">

    <div v-if="isInPast" class="past-artwork">
      <p>This artwork has passed.</p>
    </div>

    <div class="card-top">

      <h3 class="artwork-name"><a tabindex="-1" ref="windowHeading" :href="`/artwork/${artwork.slug}`"><span
          class="sr-only">Artwork Name: </span>{{ name }}</a></h3>
      <h4 class="artist">{{ artist }}</h4>

      <FavouriteButton :item-name="`${name} by ${artist}`" :item-id="artwork.id"/>

    </div>

    <div class="address">
      <p>
        <img :src="getPin(artwork.artwork_type)" alt=""/>
        <strong><span class="sr-only">Artwork Type: </span>{{ artwork.artwork_type }}</strong>
      </p>
      <p><span class="sr-only">Artwork Address: </span>{{ artwork.address }}</p>

      <div v-if="!hideMapLink">
        <button v-if="!isExternal" @click="handleClick(artwork)" class="no-style" :aria-label="`Show ${name} on Map`">
          Show on map
        </button>
        <a v-if="isExternal" :href="`/?activeArtwork=${artwork.id}#map-container`" class="no-style"
           :aria-label="`Show ${name} on Map`">Show on map</a>
      </div>

    </div>

    <div v-if="artwork.media.length > 0 && !hideCarousel">
      <ImageCarousel
          :images="[
              {
                original_url: artwork.images.thumb.url, // add original image as the first slider option, but use the media structure since that's what the component expects
                custom_properties: { alt_text: artwork.alt_text }
              }, ...artwork.additional_images]"/>
    </div>
    <div class="image" v-else-if="artwork.local_image">
      <img :src="artwork.images.thumb.url" :alt="artwork.name + ' in situ'"/>
    </div>

    <div class="artwork-hub" v-if="artwork.hubs && artwork.hubs.length > 0">
      <p>Hub: <span v-for="hub in artwork.hubs" :key="hub.id"><a :href="`/hub/${hub.slug}`">{{ hub.title }}</a></span>
      </p>
    </div>

    <div v-if="isTemporary" class="artwork-dates">
      <p> {{ new Date(artwork.start_date).toLocaleDateString('en-US', dateOptions) }} -
        {{ new Date(artwork.end_date).toLocaleDateString('en-US', dateOptions) }} </p>
    </div>

    <div class="additional-info-card">

      <p v-if="artwork.medium"><span>Medium:</span> {{ artwork.medium }}</p>
      <p v-if="artwork.year"><span>Year Created:</span> {{ artwork.year }}</p>
    </div>

  </div>
</template>

<script>
import FavouriteButton from "../../favourites/FavouriteButton";
import {pins} from '../../../mixins/pins';
import LearnMoreButton from "../buttons/LearnMoreButton";
import ImageCarousel from "../../global/ImageCarousel";

export default {
  name: 'Card',
  components: {ImageCarousel, LearnMoreButton, FavouriteButton},
  props: ['artwork', 'isExternal', 'hideMapLink', 'hideCarousel'],
  mixins: [pins],
  data() {
    return {
      dateOptions: {year: 'numeric', month: 'short', day: 'numeric'}
    }
  },
  computed: {
    name() {
      return this.artwork.name ? this.artwork.name : 'Untitled';
    },
    artist() {
      return this.artwork.artist ? this.artwork.artist : 'Unknown Artist';
    },
    isTemporary() {
      return (this.artwork.start_date);
    },
    isInPast() {

      if (this.artwork.end_date) {
        return new Date(this.artwork.end_date).getTime() < new Date().getTime();
      }

      return false;
    },
    cardClass() {
      if(this.artwork.media.length > 0) {
        return 'multi-image';
      } else if(this.artwork.local_image) {
        return 'has-image';
      } else {
        return 'no-image';
      }
    }
  },
  methods: {
    handleClick(artwork) {

      document.getElementById('map-container').scrollIntoView();

      this.$store.dispatch('setActiveArtwork', artwork);
      this.$store.dispatch('setArtworksView', 'map');

    },
  }
}
</script>
