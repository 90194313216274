import {helpers} from "../helpers/artworx-helpers";

const state = {

	featuredEvent: {},
	initialEvents: [],
	filteredEvents: [],
	searchedEvents: [],
	
	eventSearchTerm: '',
	
	options: {
		categories: []
	},

	categories: [],
	dates: [],
	showPastEvents: false,
	params: []

};

const mutations = {
	initialEvents(state, data) {
		return state.initialEvents = data;
	},
	filteredEvents(state, data) {
		return state.filteredEvents = data;
	},
	featuredEvent(state, data) {
		return state.featuredEvent = data;
	},
	categories(state, data) {
		return state.categories = data;
	},
	options(state, data) {
		return state.options = data;
	},
	dates(state, data) {
		return state.dates = data;
	},
	showPastEvents(state, data) {
		return state.showPastEvents = data;
	},
	searchedEvents(state, data) {
		return state.searchedEvents = data;
	},
	eventSearchTerm(state, data) {
		return state.eventSearchTerm = data;
	},
};

const getters = {

	getEventFilters(state) {
		let {categories, dates} = state;
		return {categories, dates};
	},

	getActiveEventFiltersBool(state) {
		return (state.searchedEvents.length > 0 || state.categories.length > 0 || state.dates.length > 0);
	},

	getCategoryOptions(state) {
		return state.options.categories;
	},

	getActiveEventFilters(state) {

		let {categories, dates, eventSearchTerm} = state;

		const parsedDates = dates.map(date => new Date(date).toDateString());

		return {categories, parsedDates, eventSearchTerm};

	}

};

const actions = {

	setFeaturedEvent({commit, dispatch}, event) {
		commit("featuredEvent", event)
	},

	setInitialEvents({commit, dispatch}, events) {
		commit("initialEvents", events);
	},

	filterEvents({commit, state, dispatch}) {

		let filteredEvents = [...state.initialEvents];
		
		if(state.eventSearchTerm && state.searchedEvents) {
			filteredEvents = [...state.searchedEvents];
			console.log('there are filtered events');
		}
		
		// Filter out past events if not selected
		if(!state.showPastEvents) {
			filteredEvents = filteredEvents.filter(event => event.is_current);
		}

		filteredEvents = filteredEvents.filter((event) => {

			return (state.categories.some(cat => event.categories.map(cat => cat.name).includes(cat)) || !state.categories.length);

		});

		if(state.dates.length > 0) {

			filteredEvents = filteredEvents.filter((event) => {

				let inRange = false;

				if(event.sessions.length > 0) {

					event.sessions.forEach(session => {

						if(!inRange) { // if we still aren't in range, keep checking more dates
							inRange = helpers.isDateInRange(state.dates, session.start_datetime, session.end_datetime);
						}

					});

				}

				return inRange;

			});

		}

		commit("filteredEvents", filteredEvents);
		dispatch('setEventsUrl');

	},
	
	setEventSearchTerm({commit}, term) {
		console.log(term);
		commit('eventSearchTerm', term);
	},
	
	setSearchedEvents({commit, dispatch}, events) {
		console.log(events);
		commit("searchedEvents", events);
		dispatch("filterEvents");
	},
	
	clearEventSearch({commit, dispatch}) {
		commit("searchedEvents", []);
		commit("eventSearchTerm", "");
		dispatch("filterEvents");
	},
	
	setCategoryFilter({commit, state, dispatch}, payload) {
		let {type, data} = payload;
		commit(type, data);
		dispatch('filterEvents');
	},

	setCategoryOptions({commit, state}, payload) {

		let {type, items} = payload;
		let options = {...state.options};

		options[type] = items;

		commit('options', options);

	},

	setDates({commit, state, dispatch}, dates) {
		commit('dates', dates);
		dispatch('filterEvents');
	},

	setEventsUrl({getters}) {

		let params = '';

		if(getters.getActiveEventFiltersBool) {

			let filters = getters.getEventFilters;

			params += '?';

			for(const [key, value] of Object.entries(filters)) {

				if(value.length > 0) {

					if((typeof value) == 'object') {

						value.forEach((item) => {
							params += `${key}[]=${encodeURIComponent(item)}&`;
						})

					} else if((typeof value) == 'string') {
						params += `${key}=${encodeURIComponent(value)}&`;
					}

				}

			}

			params = params.replace(/&$/, '');

		}

		window.history.replaceState({}, "ArtworxTO Events", params);

	},

	setInitialEventFilters({commit, dispatch, state}, params) {

		for(const [key, value] of Object.entries(params)) {

			if(key in state) {
				commit(key, value);
			}

		}

		dispatch('filterEvents');

	},
	
	setShowPastEvents({commit, dispatch}, input) {
		commit('showPastEvents', input);
		dispatch('filterEvents');
	},

	clearEventFilters({commit, dispatch}) {
		commit('categories', []);
		commit('dates', []);
		dispatch('filterEvents');
	}


};

export default {
	state,
	mutations,
	getters,
	actions
}