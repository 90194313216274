<template>
  <div class="resource-library">

    <OnDemandFilters />

    <div class="resource-listing-container">
        <ResourceCard  v-for="resource in filteredOnDemand" :key="resource.slug" :resource="resource" />
    </div>

    <button v-if="filteredOnDemand.length > increment && currentIncrement < filteredOnDemand.length"
        class="styled-button reversed" @click="loadMore">+ Load More</button>

    <div v-if="!filteredOnDemand.length" class="no-items">
      <p>There are no On Demand items matching the filters.</p>
    </div>

  </div>
</template>

<script>

import ResourceCard from "./ResourceCard";
import FilterBar from "../global/FilterBar";
import {slugify} from "../../mixins/slugify";
import OnDemandFilters from "./ResourceFilters";
import {mapState} from "vuex";

export default {
  components: {OnDemandFilters, FilterBar, ResourceCard},
  props: ['resources', 'params'],
  name: 'ResourceLibrary',
  mixins: [slugify],
  data() {
    return {
      increment: 9,
      currentIncrement: 9,
    }
  },
  mounted() {
    this.$store.dispatch('setInitialOnDemandFilters', JSON.parse(this.params));
    this.$store.dispatch('setInitialOnDemand', JSON.parse(this.resources));
  },
  computed: {
    ...mapState({
      filteredOnDemand: state => state.onDemand.filteredOnDemand,
    })
  },
  methods: {
    loadMore() {
      this.currentIncrement += this.increment;
    }
  }
}
</script>

