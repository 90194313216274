<template>
	<div class="blog-listing">
		<BlogCard v-for="blog in parsedBlogs" :key="blog.id" :blog="blog" />
	</div>
</template>

<script>
import BlogCard from "./BlogCard";
export default {
	name: "BlogList",
	components: {BlogCard},
	props: ['blogs'],
	data() {
		return {
			parsedBlogs: []
		}
	},
	mounted() {
		this.parsedBlogs = JSON.parse(this.blogs);
	}
}
</script>