import Vue from 'vue'

export const helpers = {
	checkOption(arr, val) {

		if(!arr.includes(val) && val) {
			return [...arr, val];
		}

		return [...arr];

	},
	updateScreenReaderFilterStatus(message) {
		let statusEl = document.getElementById('filter-status');

		if(statusEl) {
			statusEl.innerText = message;
		}

	},
	updateScreenReaderViewStatus(message) {
		let statusEl = document.getElementById('results-view-status');

		if(statusEl) {
			statusEl.innerText = message;
		}
	},

	checkACookieExists(name) {
		return document.cookie.split(';').some((item) => item.trim().startsWith( name + '='));
	},

	getCookieValuesByName(name) {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		let ids = [];

		if(parts.length === 2) {
			ids = parts.pop().split(';').shift().split(',');
			ids = ids.filter(i => i); // remove blanks
		}

		return ids;
	},

	isDateInRange(range, startDate, endDate) {

		// range is an array of various dates
		// we need to check if any of them fall within the start and end dates
		const start = Vue.moment(startDate, 'YYYY-MM-DD');
		const end = Vue.moment(endDate, 'YYYY-MM-DD');

		const datesInRange = range.filter(date => {

			const dateToCompare = Vue.moment(date);
			return (dateToCompare.isSameOrAfter(start) && dateToCompare.isSameOrBefore(end));

		});

		return datesInRange.length;

	}

};