<template>
	<div class="starting-location">
		<img src="../../assets/images/pins/pin-start.png" alt="Starting location icon" />
		<strong>Starting Location: </strong>
		{{ this.startingLocation.formatted_address }}
		<router-link v-if="allowEdit" to="/favourites/plan-a-route/select-location">Edit</router-link>
	</div>
</template>

<script>
import {mapState} from "vuex";

export default {
	name: "StartingLocation",
	props: {
		allowEdit: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		...mapState({
			startingLocation: state => state.planRoute.startingLocation,
		}),
	}
}
</script>