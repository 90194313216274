<template>
  <div class="plan-a-route-intro">
    <h2>How to Plan Your Route</h2>
    <ul>

      <li>

        <div>
          <div class="step-count"><span>1</span></div>

          <div>
            <h3>Choose the Artworks</h3>
            <p>You can select two or more of your favourited artworks that you would like to include on your route.</p>
          </div>

        </div>

        <div class="image-container">
          <img src="/images/step-1-img.jpg" alt=""/>
        </div>

      </li>
      <li>

        <div>
          <div class="step-count"><span>2</span></div>

          <div>
            <h3>Pick a Starting Location</h3>
            <p>You will be prompted to enter a location to start your route. This can be your address, a subway stop or
              even a well-known city landmark.</p>
            <p>You can also chose whether you would like directions for walking, driving or biking.</p>
          </div>
        </div>

        <div class="image-container">
          <img src="/images/step-2-img.jpg" alt=""/>
        </div>

      </li>
      <li>

        <div>

          <div class="step-count"><span>3</span></div>

          <div>
            <h3>Edit Your Route</h3>
            <p>You will be able to customize your route by choosing the order in which you’ll visit the artworks.</p>
          </div>

        </div>

        <div class="image-container">
          <img src="/images/step-3-img.jpg" alt=""/>
        </div>

      </li>
      <li>

        <div>

          <div class="step-count"><span>4</span></div>

          <div>
            <h3>Save Your Route</h3>
            <p><strong>ArtworxTO does not collect or save any personal information, nor does it allow you to edit your
              route once you exit this experience.</strong></p>
            <p>Once you are content with your customized route, you will be prompted to send yourself an email that
              summarizes your route, so you can save it for future reference. You will also be given a link to your
              customized route, that you can email and share it with others!</p>
          </div>

        </div>


        <div class="image-container">
          <img src="/images/step-4-img.jpg" alt=""/>
        </div>

      </li>

    </ul>

    <div class="next-button">
      <router-link @click.native="sendToOracle" to="/favourites/plan-a-route/select-artworks"
                   class="styled-button reversed">Get Started <span></span></router-link>
    </div>

  </div>
</template>

<script>
export default {
  name: "Intro",
  methods: {
    sendToOracle() {
      if (window.dcsMultiTrack) {
        window.dcsMultiTrack('WT.ihtml', 'Get Started');
      }
    }
  },
}
</script>