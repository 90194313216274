<template>
	<div class="filter-container">
		
		<div class="filter-header">
			<button
          @click="toggleContent"
          :class="['filter-toggle-button', toggleState ? 'open' : '']"
          :aria-label="`Toggle ${label} filter options visibility`"
          :aria-expanded="String(toggleState)"
          :aria-control="`${filterType}-container`"
          :ref="`${filterType}-container`"
          :id="`${filterType}-group`"
      >{{ label }}</button>
		</div>
		
		<div :id="`${filterType}-container`" class="filter-content" v-show="toggleState">
			
			<form :id="filterType">
        <fieldset>
          <legend class="sr-only">{{ label }}</legend>
          <slot></slot>
        </fieldset>
			</form>
			
		</div>
		
	</div>
</template>

<script>
	import ClearButton from "../../artworks/buttons/ClearButton";
	export default {
		name: "FilterContainer",
		components: {ClearButton},
		props: {
			label: {
				type: String,
				default: ''
			},
			filterType: {
				type: String,
				default: ''
			},
			groupType: {
				type: String,
				default: ''
			},
		},
		data() {
			return {
				toggleState: true
			}
		},
		methods: {
			toggleContent() {
				this.toggleState = !this.toggleState;
			}
		}
	}
</script>