<template>
	<div class="select-card">
		
		<div class="details show-for-mobile">
			<h3 class="artwork-name"><a :href="`/artwork/${artwork.slug}`" target="_blank"><span class="sr-only">Artwork Name: </span>{{ name }}</a></h3>
			<h4 class="artist"><span class="sr-only">Artwork Artist: </span>{{ artist }}</h4>
		</div>
		
		<div class="card-content">
			
			<div class="image" v-if="artwork.local_image">
				<img :src="artwork.images.thumb.url" :alt="artwork.name + ' in situ'" />
			</div>
			
			<div class="details">
				
				<div class="hide-for-mobile">
					<h3 class="artwork-name"><a :href="`/artwork/${artwork.slug}`" target="_blank"><span class="sr-only">Artwork Name: </span>{{ name }}</a></h3>
					<h4 class="artist">{{ artist }}</h4>
				</div>
				
				<div class="address">
					<img :src="getPin(artwork.artwork_type)" alt="" />
					<div>
						<p class="artwork-type"><strong><span class="sr-only">Artwork Type: </span>{{ artwork.artwork_type }}</strong></p>
						<p class="artwork-address"><span class="sr-only">Artwork Address: </span>{{ artwork.address }}</p>
					</div>
				</div>
			
			</div>
		</div>
		
	</div>
</template>

<script>
import {pins} from "../../../mixins/pins";

export default {
	name: "SelectCard",
	props: ['artwork'],
	mixins: [pins],
	computed: {
		name() {
			return this.artwork.name ? this.artwork.name : 'Untitled';
		},
		artist() {
			return this.artwork.artist ? this.artwork.artist : 'Unknown Artist';
		}
	},
}
</script>

<style scoped>

</style>