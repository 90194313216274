import {MapElementFactory} from "vue2-google-maps";

export default MapElementFactory({
	name: "directionsRenderer",

	ctr: () => google.maps.DirectionsRenderer,

	events: ['directions_changed'],

	mappedProps: {
		routeIndex: { type: Number },
		options: { type: Object },
		panel: { },
		directions: { type: Object }
	},

	props: {
		origin: {type: Object},
		destination: {type: Object},
		travelMode: {type: String},
		waypoints: {type: Array}
	},

	afterCreate(directionsRenderer) {

		let options = {
			suppressMarkers: true,
			clickable: false,
			polylineOptions: {
				strokeColor: '#000000',
				strokeWidth: 6
			}
		};

		directionsRenderer.setOptions(options);

		let directionsService = new google.maps.DirectionsService();

		let {origin, destination, travelMode, waypoints} = this;

		if(!origin || !destination || !travelMode) {
			return;
		}

		let request = {
			origin,
			destination,
			travelMode,
			waypoints
		};

		directionsService.route(request,
			(result, status) => {

				if(status !== "OK") {
					return;
				}

				directionsRenderer.setDirections(result);

				this.$emit('directions_changed', result.routes[0]);

			}
		);

		this.$watch(
			() => [this.origin, this.destination, this.travelMode, this.waypoints],
			() => {
				let { origin, destination, travelMode, waypoints } = this;

				let request = {
					origin,
					destination,
					travelMode,
					waypoints
				};

				directionsService.route(request,
					(result, status) => {

						if(status !== "OK") {
							return;
						}

						directionsRenderer.setDirections(result);

						this.$emit('directions_changed', result.routes[0]);

					}
				);

			},
			{deep: true}
		);

	}
});
