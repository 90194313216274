var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image-carousel"},[(_vm.parsedImages.length > 1)?_c('hooper',{ref:"carousel",attrs:{"settings":{
        transition: 600,
        wheelControl: false,
        shortDrag: false,
        infiniteScroll: false,
        trimWhiteSpace: true,
        itemsToShow: 1
      }}},_vm._l((_vm.parsedImages),function(image){return _c('slide',{key:image.original_url},[_c('img',{attrs:{"src":image.original_url,"alt":""}})])}),1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"carousel-control"},[(_vm.parsedImages.length > 1)?_c('div',{staticClass:"controls"},[_c('button',{attrs:{"aria-label":"Slide Previous"},on:{"click":function($event){$event.preventDefault();return _vm.slidePrev.apply(null, arguments)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPrevDisabled),expression:"!isPrevDisabled"}],attrs:{"src":require('../../assets/images/arrows/Left.svg'),"alt":""}})]),_vm._v(" "),_c('p',{staticClass:"item-count"},[_c('span',{staticClass:"sr-only"},[_vm._v("Current Slide:")]),_vm._v(" "+_vm._s(_vm.currentIndex + 1)+" / "+_vm._s(_vm.parsedImages.length))]),_vm._v(" "),_c('button',{attrs:{"aria-label":"Slide Next"},on:{"click":function($event){$event.preventDefault();return _vm.slideNext.apply(null, arguments)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isNextDisabled),expression:"!isNextDisabled"}],attrs:{"src":require('../../assets/images/arrows/Right.svg'),"alt":""}})])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }