export var hearts = {
	data() {
		return {
			hearts: {
				empty: require('../assets/images/icon-heart-empty.svg'),
				filled: require('../assets/images/icon-heart-filled.svg'),
				white: require('../assets/images/icon-heart-white.svg')
			}
		}
	}
}