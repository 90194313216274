<template>
	<div class="search-container">
		
		<div class="search-bar">
			<form autocomplete="off" id="ArtworkSearch" @submit="submit">
				
				<label for="search">{{ label }}</label>
				
				<div class="search-input-group">
					<div class="search-input-wrapper">
						<input id="search" ref="search" type="text" v-model="term" :aria-label="label" placeholder="Start searching..." aria-describedby="search-error" />
						
						<button v-if="term.length > 0" class="no-style clear-search" @click="clearAll" aria-label="Clear search" type="button">
							<img src="/images/x.svg" alt="" />
						</button>
					</div>
					
					<button type="submit">Search</button>
				
				</div>
			
			</form>
		</div>
		
		<div id="search-error" class="search-messages" aria-live="assertive">
			<p v-if="error">{{ error }} <strong>{{ searchedTerm }}</strong>. Please try searching for something else.</p>
		</div>
	
	</div>
</template>

<script>
export default {
	name: 'SearchBar',
	props: ['label', 'clearDispatchAction', 'resultsDispatchAction', 'termDispatchAction', 'endpoint'],
	data() {
		return {
			error: null,
			term: '',
			searchedTerm: '',
		}
	},
	methods: {
		clearAll() {
			this.$store.dispatch(this.clearDispatchAction);
			this.error = '';
			this.term = '';
			this.$emit('reset');
		},
		submit(e) {
			e.preventDefault();
			
			if(!this.term.length) return;
			
			this.searchedTerm = this.term;
			this.error = '';
			
			if(this.termDispatchAction) {
				this.$store.dispatch(this.termDispatchAction, '');
			}
			
			axios
				.get(`${this.endpoint}?q=` + this.term)
				.then(response => {
					
					if(response.data.error) {
						this.error = response.data.error;
					} else if(response.data.data.length) {
						
						if(this.termDispatchAction) {
							this.$store.dispatch(this.termDispatchAction, this.term);
						}
						
						if(this.resultsDispatchAction) {
							this.$store.dispatch(this.resultsDispatchAction, response.data.data);
						}
						
						this.$emit("search");
					} else {
						this.error = 'There were no results for your search term';
					}
					
					if(this.error.length > 0) {
						this.$refs.search.focus();
					}
					
				});
			
		}
	}
}
</script>