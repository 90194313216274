<template>
	<div class="info-window-html">
		<div class="info-content-container" v-if="artwork">
			
			<div>
				<Card ref="windowHeading" :hide-map-link="true" :artwork="artwork" />
			</div>

		</div>
	</div>

</template>

<script>
import Card from "./Card";
import {mapState} from "vuex";

export default {
	name: 'InfoWindowHTML',
	components: {Card},
	computed: {
		...mapState({
			artwork: state => state.artworks.activeArtwork
		}),
	}
}
</script>