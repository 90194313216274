export var keyboardControl = {
	data() {
		return {
			keys: {
				end: 35,
				home: 36,
				left: 37,
				up: 38,
				right: 39,
				down: 40,
				delete: 46,
				enter: 13,
				space: 32
			},
		}
	}
}