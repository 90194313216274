<template>
    <div class="hero-video" >
        <video aria-hidden="true" preload="auto" ref="video" autoplay muted loop playsinline :poster="posterImage">
            <source :src="videoFile" type='video/mp4' />
            Your browser doesn't support video
        </video>
        <div class="video-controls">
            <button @click="togglePlaying" :aria-label="playControlAria">
                <img :src="playIcon" alt="" v-if="!playing" />
                <img :src="pauseIcon" alt="" v-if="playing" />
            </button>
            <button v-if="audio" @click="toggleMuted" :aria-label="audioControlAria">
                <img :src="muteIcon" alt="" v-if="!muted" />
                <img :src="unmuteIcon" alt="" v-if="muted" />
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "HeroVideo",
    props: {
        videoFile: {
            type: String,
            default: ''
        },
        posterImage: {
            type: String,
            default: ''
        },
        audio: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            playing: true,
            muted: true,
            playIcon: require('../../assets/images/icon-play.svg'),
            pauseIcon: require('../../assets/images/icon-pause.svg'),
            muteIcon: require('../../assets/images/icon-mute.svg'),
            unmuteIcon: require('../../assets/images/icon-unmute.svg'),
        }
    },
    computed: {
      playControlAria() {
        return this.playing ? 'Pause decorative background video' : 'Play decorative background video';
      },
      audioControlAria() {
        return this.muted ? 'Unmute decorative background video' : 'Mute decorative background video';
      }
    },
    methods: {
        togglePlaying() {

            if(this.playing) {
                this.$refs.video.pause();
            } else {
                this.$refs.video.play();
            }

            this.playing = !this.playing;

        },
        toggleMuted() {
            this.muted = !this.muted;
            this.$refs.video.muted = this.muted;
        }
    }
}
</script>
