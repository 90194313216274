import {mapState} from "vuex";

export var diamonds = {
	computed: {
		...mapState({
			categories: state => state.events.options.categories
		})
	},
	methods: {
		getDiamondColour(cat) {
			const item = this.categories.find(category => category.name === cat);
			return item ? item.icon_hex_colour : '#FFFFFF';
		}
	}
}