<template>
  <div class="artwork-card blank-card">
		<p>Want to save artworks to your favourites?</p>
		<a href="/#Artworks" >Explore Artworks</a>
  </div>
</template>

<script>
export default {
  name: 'BlankCard'
}
</script>