<template>
	<div class="container read-only-route">
		<h1>My Route Map</h1>
		
		<RouteMap />
		
		<div class="directions-heading">
			<h2>Directions</h2>
			<a :href="googleUrl" target="_blank">View My Route on Google Maps</a>
		</div>
		
		<div class="route-details-heading">
			
			<div class="total-route-info">
				<TotalDistance />
				<TotalTime />
			</div>
			
			<StartingLocation :allow-edit="false" v-if="startingLocation" />
		
		</div>
		
		<div class="route-details">
			<RouteDirections />
		</div>
		
		
	</div>
</template>

<script>
import RouteMap from "./RouteMap";
import RouteDirections from "./RouteDirections";
import TotalDistance from "./TotalDistance";
import TotalTime from "./TotalTime";
import StartingLocation from "./StartingLocation";
export default {
	name: "ReadOnlyRoute",
	components: {StartingLocation, TotalTime, TotalDistance, RouteDirections, RouteMap},
	props: ['artworks', 'startingLocation', 'travelMode', 'googleUrl'],
	mounted() {
		let selectedArtworks = JSON.parse(this.artworks);
		let startingLocation = JSON.parse(this.startingLocation);
		let travelMode = this.travelMode;
		
		this.$store.dispatch('setSelections', selectedArtworks);
		this.$store.dispatch('setStartingLocation', startingLocation);
		this.$store.dispatch('setTravelMode', travelMode);
	}
}
</script>