<template>
  <div v-if="event" class="event-card">

    <div v-if="imageURL" class="event-card-image">
      <div v-if="!event.is_current" class="past-event">
        <p>This event has passed</p>
      </div>
      <img :src="imageURL" :alt="`Event image for ${event.title}`" />
    </div>

    <div class="event-card-content">

      <div v-if="event.programming_partner" class="event-program-partner">
        <p>{{ event.programming_partner }} Event</p>
      </div>
	
			<div class="event-card-intro">
				<h3 class="event-name"><a :href="`/event/${event.slug}`" :aria-label="`View ${event.title} details`">{{ event.title }}</a></h3>
				<FavouriteButton :item-id="event.id" :item-name="event.title" favourites-group="events" />
			</div>

      <div class="event-card-details">
				
				<div v-if="event.formatted_address" class="event-address">
					<p>
						<span class="sr-only">Event Location: </span>
						{{ event.formatted_address }}
					</p>
				</div>
				
        <div :class="['event-times', !hasSessions && !event.formatted_address ? 'no-border' : '']">

          <p v-if="hasSessions && !event.is_current"  v-for="session in event.formatted_sessions" :key="session">
            <span class="sr-only">Event Date and Time(s): </span>
						{{ session }}
          </p>
          <p v-if="hasSessions && event.is_current"  v-for="session in event.formatted_future_sessions" :key="session">
            <span class="sr-only">Event Date and Time(s): </span>
						{{ session }}
          </p>
					<p v-if="!hasSessions"><span class="sr-only">Event Date and Time: </span>TBD</p>
					
					<CalendarLink v-if="hasSessions" :id="event.id" />
					
				</div>
				
				<div class="event-categories" v-if="event.categories && event.categories.length > 0">
					<span class="sr-only">Event Categories: </span>
					<ul>
						<li v-for="category in event.categories">
							<div class="diamond-icon" :style="{'backgroundColor': category.icon_hex_colour}"></div> {{ category.name }}
						</li>
					</ul>
				</div>
				
      </div>

    </div>

  </div>
</template>

<script>
import CalendarLink from "./CalendarLink";
import FavouriteButton from "../favourites/FavouriteButton";

export default {
	components: {CalendarLink, FavouriteButton},
	props: ['event', 'showDescription'],
  name: 'EventCard',
	computed: {
    imageURL() {
			
    	if(!('images' in this.event)) {
    		return false;
			}

      if(!Object.keys(this.event.images).length) {
        return false;
      }

      return this.showDescription ? this.event.images.medium.url : this.event.images.thumb.url;

    },
		hasSessions() {
    	return ('sessions' in this.event) && this.event.sessions.length > 0;
		}
  }
}
</script>

