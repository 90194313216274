<template>
	<div class="route-order-edit">
		
		<div class="leg order-edit" v-for="(artwork, n) in artworkSelections" :key="artwork.id">
			
			<div class="order-number"><span>{{ n + 1 }}</span></div>
			
			<div>
				
				<div class="arrow-buttons">
					<button @click="moveUp(n)" v-if="n !== 0" aria-label="Move Up">
						&#9650;</button>
					<button @click="moveDown(n)" v-if="n !== artworkSelections.length - 1" aria-label="Move Down">&#9660;</button>
				</div>
				
				<SelectCard :artwork="artwork" />
				
			</div>
			
		</div>
	
	</div>
</template>

<script>
import {mapState} from "vuex";
import SelectCard from "../artworks/cards/SelectCard";

export default {
	name: "RouteOrderEdit",
	components: {SelectCard},
	computed: {
		...mapState({
			artworkSelections: state => state.planRoute.artworkSelections,
		}),
	},
	methods: {
		move(currentIndex, direction) {
			// get our array, make a copy of it because splice is destructive
			let artworks = [...this.artworkSelections];
			const desiredIndex = currentIndex + direction; // direction is either 1 or -1
			
			// get the item at the index we're currently at, and the item at the desired index
			let currentItem = artworks[currentIndex];
			let itemToSwapWith = artworks[desiredIndex];
			
			// we want to swap the items at those indices
			artworks.splice(desiredIndex, 1, currentItem);
			artworks.splice(currentIndex, 1, itemToSwapWith);
			
			// save the new array to state
			this.$store.dispatch("setSelections", artworks);
			
		},
		moveUp(currentIndex) {
			this.move(currentIndex, -1)
		},
		moveDown(currentIndex) {
			this.move(currentIndex, 1);
		}
	}
}
</script>
