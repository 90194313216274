<template>

	<div class="sort-select">
		
		<label for="Store-sort">Sort Stores:</label>

    <div class="select-wrapper">
      <select id="Store-sort" @change="setSort" v-model="sort">
        <option value="newest" selected>Newest to Oldest</option>
        <option value="oldest">Oldest to Newest</option>
        <option value="a-z">A-Z</option>
        <option value="z-a">Z-A</option>
      </select>
    </div>
		
	</div>

</template>

<script>
export default {
	name: "Sort",
  data() {
    return {
      sort: "newest"
    }
  },
	methods: {
		setSort() {
			this.$store.dispatch("setArtForSaleSortDirection", this.sort);
		}
	}
}
</script>