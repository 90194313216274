const state = {
	initialOnDemand: [],
	filteredOnDemand: [],
	
	types: [],
	activeTypes: [],
	
	hubs: [],
	activeHubs: []
};

const getters = {
	
	getActiveOnDemandFiltersBool(state) {
		
		return (state.activeTypes.length > 0 || state.activeHubs.length > 0);
		
	},
	
	getActiveOnDemandFilters(state) {
		
		let {activeTypes, activeHubs} = state;
		return {activeTypes, activeHubs};
		
	}
	
};

const actions = {
	
	setInitialOnDemand({commit, dispatch}, onDemands) {
		commit("initialOnDemand", onDemands);
		dispatch("filterOnDemand");
	},
	
	setInitialOnDemandFilters({commit, dispatch, state}, params) {
		
		for(const [key, value] of Object.entries(params)) {
			
			if(key === 'activeTypes') {
				commit("activeOnDemandTypes", value);
			}
			
			if(key === 'activeHubs') {
				commit("activeOnDemandHubs", value);
			}
			
		}
		
	},
	
	setOnDemandFilterUrl({state, getters}) {
		
		let params = '';
		
		if(getters.getActiveOnDemandFiltersBool) {
			
			let filters = getters.getActiveOnDemandFilters;
			
			params += '?';
			
			for(const [key, value] of Object.entries(filters)) {
				
				if(value.length > 0) {
					
					if((typeof value) == 'object') {
						
						value.forEach((item) => {
							params += `${key}[]=${encodeURIComponent(item)}&`;
						})
						
					}
					
				}
				
			}
			
			params = params.replace(/&$/, '');
			
		}
		
		window.history.replaceState({}, "ArtworxTO On Demand", params);
		
	},
	
	filterOnDemand({commit, state, dispatch}) {
		
		// Filter from the initial set
		let onDemands = [...state.initialOnDemand];
		
		// Type selections
		let filteredOnDemand = onDemands.filter((onDemand) => {
			return state.activeTypes.includes(onDemand.type.name) || !state.activeTypes.length;
		}).filter((onDemand) => {
			return (onDemand.hub && state.activeHubs.includes(onDemand.hub.title)) || !state.activeHubs.length;
		});
		
		commit("filteredOnDemand", filteredOnDemand);
		dispatch('setOnDemandFilterUrl');
	},
	
	setOnDemandTypes({commit}, categories) {
		commit("onDemandTypes", categories);
	},
	
	setActiveOnDemandTypes({commit, dispatch, state}, payload) {
		let {type, data} = payload;
		commit(type, data);
		dispatch('filterOnDemand');
	},
	
	setOnDemandHubs({commit}, categories) {
		commit("onDemandHubs", categories);
	},
	
	setActiveOnDemandHubs({commit, dispatch, state}, payload) {
		let {type, data} = payload;
		commit(type, data);
		dispatch('filterOnDemand');
	},
	
	clearActiveOnDemandTypes({commit, dispatch}) {
		commit('activeOnDemandTypes', []);
		dispatch('filterOnDemand');
	},
	
	clearActiveOnDemandHubs({commit, dispatch}) {
		commit('activeOnDemandHubs', []);
		dispatch('filterOnDemand');
	},
	
	clearAllOnDemandFilters({commit, dispatch}) {
		commit('activeOnDemandTypes', []);
		commit('activeOnDemandHubs', []);
		dispatch('filterOnDemand');
	}
	
};

const mutations = {
	initialOnDemand(state, data) {
		return state.initialOnDemand = data;
	},
	filteredOnDemand(state, data) {
		return state.filteredOnDemand = data;
	},
	onDemandTypes(state, data) {
		return state.types = data;
	},
	activeOnDemandTypes(state, data) {
		return state.activeTypes = data;
	},
	onDemandHubs(state, data) {
		return state.hubs = data;
	},
	activeOnDemandHubs(state, data) {
		return state.activeHubs = data;
	}
};

export default {
	state,
	mutations,
	getters,
	actions
}