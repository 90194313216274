<template>
  <div id="EmailList" class="email-favourites">

    <div v-if="success === false">
			
      <p>{{ introText }}</p>
      <div class="email-favourites-bar">
				
        <form id="EmailFavourites" @submit="submit">
          <div class="input-group">
            <label for="email">Email Address</label>
            <input id="email"
                   type="email"
                   ref="email"
                   v-model="email"
                   :class="[ error ? 'error-input' : '']"
                   :aria-invalid="String(hasError)"
                   aria-describedby="email-error" />
            <div id="email-error" class="error">{{ error }}</div>
          </div>

          <button class="styled-button reversed" :data-wt_params="`WT.ihtml=${buttonText}`" type="submit">{{ buttonText }}</button>
        </form>
				
      </div>
			<p style="font-size: 12px; margin-top: 30px;">The City of Toronto does not collect or store personal information (including email) from the ArtworxTO.ca website.  You can choose to send your list of favorite public artworks from the website to an email address.  Once the list has been sent, the email address will immediately be removed from the system and will not be stored for further use.</p>

    </div>

    <div class="send-success" v-show="success">
      <h2 ref="success" tabindex="-1">Thanks!</h2>
			<slot name="successText"></slot>
    </div>

  </div>
</template>

<script>
export default {
  name: 'EmailFavourites',
	props: {
  	introText: {
  		type: String,
			default: "Enter your email address below to get your saved items sent to you for offline access"
		},
		buttonText: {
			type: String,
			default: "Email my favourites"
		},
		endpoint: {
			type: String,
			default: "/api/favourites-email"
		},
		additionalData: {
  		type: Object,
			default: {}
		}
	},
  data() {
    return {
      email: "",
      error: "",
      success: false
    }
  },
  computed: {
    hasError() {
      return this.error.length > 0;
    }
  },
  methods: {
    submit(e) {
      e.preventDefault();
      
      let data = {
				email: this.email,
				...this.additionalData
			}
			
      axios
        .post(this.endpoint, data)
        .then(response => {

          if(response.data.error) {

            if(response.data.error.email) {
              this.error = response.data.error.email[0];
            } else {
              // used only if the issue is with the dispatch and not validation
              this.error = response.data.error;
            }

            this.$refs.email.focus();

          } else {
            this.success = true;
            this.error = '';
            
            if(response.data.payload) {
							this.$emit('form_success', response.data.payload);
						}

            // This focus is needed so screen readers can have the message announced
						this.$nextTick(() => {
							this.$refs.success.focus();
						})
          }
      });

    }
  }
}
</script>