<template>
  <div class="filter-bar">
    <div class="tabs" role="tablist" :aria-label="ariaLabel">

      <button
          role="tab"
          :aria-selected="String(isSelected(''))"
          :tabindex="isSelected('') ? '' : '-1'"
          :class="[isSelected('') ? 'active' : '']"
          @click="activateFilter('')"
          @keydown="handleKeyDown"
          @keyup="handleKeyUp($event, '')"
          id="view-all"
          aria-controls="view-all-tab"
          ref="view-all"
          data-value="">View All</button>

        <button
            v-for="filter in filters" :key="filter.id"
            role="tab"
            :tabindex="isSelected(filter.name) ? '' : '-1'"
            :class="[isSelected(filter.name) ? 'active' : '']"
            :data-value="filter.name"
            :aria-selected="String(isSelected(filter.name))"
            :id="slugify(filter.name)"
            :aria-controls="slugify(`${filter.name} Tab`)"
            :ref="slugify(filter.name)"
            @click="activateFilter(filter.name)"
            @keydown="handleKeyDown"
            @keyup="handleKeyUp($event, filter.name)">{{ filter.name }}</button>
    </div>
  </div>
</template>

<script>

  import { slugify } from '../../mixins/slugify';
  import {keyboardControl} from "../../mixins/keys";

  export default {
    props: {
      filters: {
        type: Array,
        default: []
      },
      ariaLabel: {
        type: String,
        default: ''
      },
			activeFilter: {
      	type: String,
				default: 'View All'
			}
    },
    name: 'FilterBar',
    mixins: [slugify, keyboardControl],
    data() {
      return {
        activeIndex: 0,
      }
    },
    computed: {
      mergedFilters() {
        return [{name: 'View All'}, ...this.filters];
      }
    },
    methods: {
      getRef(name) {
        return this.$refs[name].length ? this.$refs[name][0] : this.$refs[name];
      },
      isSelected(filter) {
        return this.activeFilter === filter;
      },
      activateFilter(filter) {
				this.updateParams(filter);
				this.$emit('filter', filter);
				this.activeIndex = this.mergedFilters.findIndex((item) => item.name === filter);
      },
			updateParams(filter) {
				let params = `?filter=${encodeURIComponent(filter)}`;
				window.history.replaceState({}, "ArtworxTO", params);
			},
      handleKeyDown(e) {
        let key = e.keyCode;

        switch (key) {
          case this.keys.end:
            e.preventDefault();
            let index = this.mergedFilters.length - 1;
            this.focusTab(index);
            break;
          case this.keys.home:
            e.preventDefault();
            this.focusTab(0);
            break;
        }
      },
      handleKeyUp(e, filterName) {
        let key = e.keyCode;

        switch (key) {
          case this.keys.space:
          case this.keys.enter:
            e.preventDefault();
            this.activateFilter(filterName);
            break;
          case this.keys.left:
            this.switchTabOnArrowPress(-1);
            break;
          case this.keys.right:
            this.switchTabOnArrowPress(1);
            break;
        }
      },
      focusTab(index) {
        let filterName = this.slugify(this.mergedFilters[index].name);
        this.getRef(filterName).focus();
      },
      switchTabOnArrowPress(direction) {
        this.activeIndex = ((this.activeIndex + direction) % this.mergedFilters.length + this.mergedFilters.length) % this.mergedFilters.length;
        this.focusTab(this.activeIndex);
      },
    }
  }
</script>