<template>
	<div class="filters-wrapper">
		
		<div class="filter-header">
			<button
				@click="toggleContent"
				:class="['filter-toggle-button', toggleState ? 'open' : '']"
				:aria-label="`Toggle all filters visibility`"
				:aria-expanded="String(toggleState)"
				:aria-control="`main-filters-container`"
				:ref="`main-filters-container`"
				:id="`main-filters-group`" v-html="label"
			></button>
		</div>
		
		<div id="main-filters-container" class="main-filter-content" v-show="toggleState">
			
			<div class="filters-container">
				<slot></slot>
			</div>
			
			<div class="filter-controls">
				<button class="no-style clear-button" @click="reset" :aria-label="`Clear all filters`">Clear All</button>
				<div class="filter-control-buttons">
					<button class="no-style apply-button" @click="apply">Apply</button>
					<button class="no-style" @click="close">Close</button>
				</div>
			</div>
		
		</div>
		
	</div>
</template>

<script>
export default {
	name: "FiltersWrapper",
	props: {
		label: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			toggleState: false
		}
	},
	methods: {
		toggleContent() {
			this.toggleState = !this.toggleState;
		},
		apply() {
			this.toggleState = false;
			this.reFocus();
			if(document.getElementById('ActiveFilters')) {
				document.getElementById('ActiveFilters').scrollIntoView();
			}
		},
		close() {
			this.toggleState = false;
			this.reFocus();
		},
		reFocus() {
			this.$refs[`main-filters-container`].focus();
		},
		reset() {
			this.$emit('reset')
		},
	}
}
</script>