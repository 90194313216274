export var pins = {
	data() {
		return {
			pins: {
				"Unknown": {
					default: require('../assets/images/pins/pin-unknown.png'),
					active: require('../assets/images/pins/pin-unknown-active.png')
				},
				"Photography": {
					default: require('../assets/images/pins/pin-photography.png'),
					active: require('../assets/images/pins/pin-photography-active.png')
				},
				"Audio / Video": {
					default: require('../assets/images/pins/pin-audiovideo.png'),
					active: require('../assets/images/pins/pin-audiovideo-active.png')
				},
				"Light Installation": {
					default: require('../assets/images/pins/pin-light.png'),
					active: require('../assets/images/pins/pin-light-active.png')
				},
				"Architecture / Landscape": {
					default: require('../assets/images/pins/pin-arch.png'),
					active: require('../assets/images/pins/pin-arch-active.png')
				},
				"Sculpture": {
					default: require('../assets/images/pins/pin-sculpture.png'),
					active: require('../assets/images/pins/pin-sculpture-active.png')
				},
				"Multidisciplinary": {
					default: require('../assets/images/pins/pin-multidis.png'),
					active: require('../assets/images/pins/pin-multidis-active.png')
				},
				"Digital": {
					default: require('../assets/images/pins/pin-digital.png'),
					active: require('../assets/images/pins/pin-digital-active.png')
				},
				"Performance": {
					default: require('../assets/images/pins/pin-performance.png'),
					active: require('../assets/images/pins/pin-performance-active.png')
				},
				"Mural / Painting": {
					default: require('../assets/images/pins/pin-painting.png'),
					active: require('../assets/images/pins/pin-painting-active.png')
				},
				"Route": {
					default: require('../assets/images/pins/pin-route.png')
				},
				"Start": {
					default: require('../assets/images/pins/pin-start.png')
				}
			},
		}
	},
	methods: {
		getPin(type) {

			let pinType = type;

			if(!this.pins[pinType]) {
				type = "Unknown";
			}

			return this.pins[type].default;
		},
		getPinType(artwork, active) {

			let type = artwork.artwork_type;

			if(!this.pins[artwork.artwork_type]) {
				type = "Unknown";
			}

			let pin = this.pins[type];

			if(this.activeArtwork && this.activeArtwork.id === artwork.id && active) {

				return {
					size: new this.google.maps.Size(32, 32),
					anchor: new this.google.maps.Point(16, 16),
					url: pin.active
				}
			}

			return {
				size: new this.google.maps.Size(32, 32),
				anchor: new this.google.maps.Point(16, 16),
				url: pin.default
			}

		},
		getMarkerIcon(type, id) {
			return {
				size: new this.google.maps.Size(32, 32),
				anchor: new this.google.maps.Point(16, 16),
				url: this.getPin(type),
				id: id || null
			}
		}
	}
}