<template>
	<div class="sort-buttons">
		
		<p>Sort Blogs:</p>
		
		<div class="states">
			
			<button @click="setSort('DESC')" :class="[$store.state.blog.sortDirection === 'DESC' ? 'active' : '', 'toggle-button']">
				<span class="sr-only">Sort by </span> Newest Posts
			</button>
			
			<button @click="setSort('ASC')" :class="[$store.state.blog.sortDirection === 'ASC' ? 'active' : '', 'toggle-button']">
				<span class="sr-only">Sort by </span> Oldest Posts
			</button>
			
		</div>
		
	</div>
</template>

<script>
export default {
	name: "Sort",
	methods: {
		setSort(direction) {
			this.$store.dispatch("setSortDirection", direction)
		}
	}
}
</script>