<template>
	<div class="route-map">
		<GmapMap
			ref="mapRef"
			:center="center"
			:zoom="zoom"
			:options="{
            mapTypeControl: false,
            fullscreenControl: false,
            styles: mapStyles
          }"
		>
			<DirectionsRenderer
				v-if="finalDestination && origin"
				
				:origin="origin"
				:travelMode="travelMode"
				:waypoints="wayPoints"
				:destination="finalDestination"
				@directions_changed="populateDirections"
			/>
			
			<GmapMarker
				ref="markerRef"
				v-if="google"
				v-for="(art, n) in artworkSelections"
				:key="art.id"
				:label="{
					text: (n + 1).toString(),
					color: 'white',
					fontWeight: 'bold'
					 }"
				:position="{lat: art.latitude, lng: art.longitude}"
				:clickable="false"
				:title="getMarkerTitle(art)"
				:icon="routePin"
			/>
			
			<GmapMarker
				ref="homeStar"
				v-if="google && startingLocation"
				:position="startingLocation.geometry ? startingLocation.geometry.location : null"
				:clickable="false"
				title="Starting Location"
				:icon="homePin"
			/>
			
		</GmapMap>
	</div>
</template>

<script>
import DirectionsRenderer from "../../utils/DirectionsRenderer";
import {mapState} from "vuex";
import {gmapApi} from "vue2-google-maps";
import {pins} from "../../mixins/pins";

export default {
	name: "RouteMap",
	components: {DirectionsRenderer},
	mixins: [pins],
	data() {
		return {
			center: {
				lat:43.687295,
				lng:-79.420690
			},
			zoom: 11,
			mapStyles: [
				{
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#f5f5f5"
						}
					]
				},
				{
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#000000"
						}
					]
				},
				{
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"color": "#f5f5f5"
						}
					]
				},
				{
					"featureType": "administrative.land_parcel",
					"elementType": "labels",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "administrative.land_parcel",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#bdbdbd"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#eeeeee"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "labels.text",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#757575"
						}
					]
				},
				{
					"featureType": "poi.business",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#c9c9c9"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "labels.text",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#9e9e9e"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#ffffff"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#e3e3e3"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#757575"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#bdbdbd"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#616161"
						}
					]
				},
				{
					"featureType": "road.local",
					"elementType": "labels",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road.local",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#9e9e9e"
						}
					]
				},
				{
					"featureType": "transit.line",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#e5e5e5"
						}
					]
				},
				{
					"featureType": "transit.station",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#eeeeee"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#1e99ae"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#9e9e9e"
						}
					]
				}
			],
		}
	},
	computed: {
		google: gmapApi,
		...mapState({
			filteredArtworks: state => state.artworks.filteredArtworks,
			artworkSelections: state => state.planRoute.artworkSelections,
			travelMode: state => state.planRoute.travelMode,
			startingLocation: state => state.planRoute.startingLocation,
			editMode: state => state.planRoute.editMode,
			legs: state => state.planRoute.legs
		}),
		routePin() {
			return {
				size: new this.google.maps.Size(32, 32),
				anchor: new this.google.maps.Point(16, 16),
				url: this.getPin('Route')
			};
		},
		homePin() {
			return {
				size: new this.google.maps.Size(32, 32),
				anchor: new this.google.maps.Point(16, 16),
				url: this.getPin('Start')
			}
		},
		origin() {
			
			if(!this.startingLocation) {
				return null;
			}
			
			return this.startingLocation.geometry.location;
		},
		wayPoints() {
			const artworks = [...this.artworkSelections];
			artworks.splice(-1, 1);
			
			if(!this.google) {
				return [];
			}
			
			// Create all of the waypoints
			return artworks.map(artwork => ({
				location: new this.google.maps.LatLng(artwork.latitude, artwork.longitude),
				stopover: true
			}));
			
		},
		finalDestination() {
			const artworks = this.artworkSelections;
			
			if(artworks.length < 1 || !this.google) {
				return null;
			}
			
			const lastItem = artworks[artworks.length - 1];
			return new this.google.maps.LatLng(lastItem.latitude, lastItem.longitude);
		},
	},
	methods: {
		populateDirections(route) {
			
			if(!route) {
				return;
			}
			
			this.$store.commit("legs", route.legs);
			this.$store.commit("polyline", route.overview_polyline);
		},
		getMarkerTitle(art) {
			
			const name = art.name ? art.name : 'Untitled Artwork';
			const artist = art.artist ? art.artist : 'Unknown Artist';
			
			return `${name} by ${artist}`;
		},
		
	}
}
</script>
