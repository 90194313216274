var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.parsedResources.length)?_c('section',{staticClass:"item-carousel resource-carousel"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"carousel-control"},[(_vm.heading)?_c('h2',{attrs:{"id":"carousel-heading"}},[_vm._v(_vm._s(_vm.heading))]):_vm._e(),_vm._v(" "),(_vm.intro)?_c('div',{staticClass:"intro",domProps:{"innerHTML":_vm._s(_vm.parsedIntro)}}):_vm._e(),_vm._v(" "),(_vm.parsedResources.length > 1)?_c('div',{class:['controls', _vm.parsedResources.length < 4 ? 'hide-on-desktop' : '']},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPrevDisabled),expression:"!isPrevDisabled"}],on:{"click":function($event){$event.preventDefault();return _vm.slidePrev.apply(null, arguments)}}},[_c('img',{attrs:{"src":require('../../assets/images/arrows/Left.svg'),"alt":"Left Arrow"}})]),_vm._v(" "),_c('p',{staticClass:"item-count"},[_vm._v(_vm._s(_vm.parsedResources.length)+" resources")]),_vm._v(" "),_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isNextDisabled),expression:"!isNextDisabled"}],on:{"click":function($event){$event.preventDefault();return _vm.slideNext.apply(null, arguments)}}},[_c('img',{attrs:{"src":require('../../assets/images/arrows/Right.svg'),"alt":"Right Arrow"}})])]):_vm._e()]),_vm._v(" "),_c('hooper',{ref:"carousel",attrs:{"settings":{
          transition: 600,
          wheelControl: false,
          shortDrag: false,
          infiniteScroll: false,
          trimWhiteSpace: true,
          breakpoints: {
            1024: {
              itemsToShow: 3,
            },
            764: {
              itemsToShow: 2,
            },
            400: {
              itemsToShow: 1,
            }
          }
        }},on:{"slide":_vm.updateAria}},_vm._l((_vm.parsedResources),function(resource){return _c('slide',{key:resource.id},[_c('ResourceCard',{attrs:{"resource":resource}})],1)}),1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }