<template>
	<div class="select-location">
		<h2 class="intro">What’s your starting address?</h2>
		
		<div class="form-group start-location">
			<label for="Location">Enter your starting address (eg, your home address, a subway station, an intersection)</label>
			<div class="location-input-wrapper">
				<gmap-autocomplete
					id="Location"
					class="introInput"
					@place_changed="getPlace"
					placeholder="eg. 123 Bay St, CN Tower, or Jane and Steeles"
					:options="{
					componentRestrictions: {country: 'ca'},
					fields: ['geometry', 'formatted_address', 'place_id']
				}" >
				</gmap-autocomplete>
			</div>
			<p class="error">{{ locationError }}</p>
		</div>
		
		<div class="form-group select">
			<label for="selectTravel">How are you getting around?</label>
			<div class="styled-select">
				<select v-model="travelMode" id="selectTravel">
					<option value="WALKING">Walking</option>
					<option value="DRIVING">Driving</option>
					<option value="BICYCLING">Bicycling</option>
				</select>
			</div>
		</div>
		
		<div class="button-group next-button">
			<router-link to="/favourites/plan-a-route" class="back-link">Back to select artworks</router-link>
			<div class="next-button">
				<button class="styled-button reversed" data-wt_track="no" @click="goNext">Next <span></span></button>
			</div>

		</div>
		
	</div>
</template>

<script>
	import {mapState} from "vuex";
	
	export default {
		name: "SelectLocation",
		data() {
			return {
				locationError: ''
			}
		},
		beforeMount() {
			if(this.artworkSelections.length < 2) {
				this.$router.push({ path: "/favourites/plan-a-route" });
			}
			
			this.$store.dispatch("setStartingLocation", {});
			
		},
		computed: {
			...mapState({
				startingLocation: state => state.planRoute.startingLocation,
				artworkSelections: state => state.planRoute.artworkSelections
			}),
			travelMode: {
				set(value) {
					this.$store.dispatch("setTravelMode", value);
				},
				get() {
					return this.$store.state.planRoute.travelMode;
				}
			}
		},
		methods: {
			goNext() {
				
				if (!this.$store.getters.getIsStartingAddressValid) {
					this.locationError = "Please select a valid location.";
				} else {
					this.$router.push({ path: "/favourites/plan-a-route/view-route" });

          if(window.dcsMultiTrack) {
            window.dcsMultiTrack('WT.ihtml', 'Next to view route');
          }

				}
				
			},
			getPlace(place) {
				this.locationError = '';
				this.$store.dispatch("setStartingLocation", place);

        if(window.dcsMultiTrack) {
          window.dcsMultiTrack('WT.address', place.formatted_address, 'WT.ihtml', 'Enter a starting location');
        }

			}
		}
	}
</script>