<template>
	<div class="select-artworks">
		<h2 class="intro">Select two or more artworks for your route.</h2>
		
		<div class="checkbox select-all" v-if="filteredArtworks.length < 11">
			<input v-model="selectAll" type="checkbox" value="all" id="select-all">
			<label for="select-all">
				<div class="check"></div>
				Select all artworks</label>
		</div>
		
		<div class="artwork-options">
			<div class="checkbox" v-for="option in filteredArtworks" :key="option.id">
				<input v-model="artworks" type="checkbox" :value="option.id" :id="option.slug">
				<label :for="option.slug">
					<div class="check"></div>
					<SelectCard :artwork="option" />
				</label>
			</div>
		</div>
		
		<div class="next-button">
			<router-link @click.native="sendToOracle" to="/favourites/plan-a-route/select-location" class="styled-button reversed" v-if="artworks.length > 1">Next <span></span></router-link>
		</div>
		
	</div>
</template>

<script>
import {slugify} from "../../mixins/slugify";
import {mapState} from "vuex";
import SelectCard from "../artworks/cards/SelectCard";

export default {
	name: "SelectArtworks",
	components: {SelectCard},
	mixins: [slugify],
	data() {
		return {
			allSelected: false
		}
	},
	computed: {
		...mapState({
			filteredArtworks: state => state.artworks.filteredArtworks,
			artworkSelections: state => state.planRoute.artworkSelections
		}),
		selectAll: {
			set(value) {
				
				if(value) {
					this.artworks = this.filteredArtworks.map((artworks) => {
						return artworks.id;
					});
				} else {
					this.artworks = [];
				}
				
			},
			get() {
				
				if(this.artworks.length === this.filteredArtworks.length) {
					return 'all';
				}
				
			}
		},
		artworks: {
			set(value) {
				
				// get the artwork objects by id
				let artworks = this.filteredArtworks.filter((artwork) => {
					return value.includes(artwork.id);
				})
				
				this.$store.dispatch("setSelections", artworks);
			},
			get() {
				// filter selections to an array of the ids
				return this.artworkSelections.map((artwork) => {
					return artwork.id;
				});
			}
		}
	},
  methods: {
    sendToOracle() {
      if(window.dcsMultiTrack) {
        window.dcsMultiTrack('WT.ihtml', 'Next to select location');
      }
    }
  },
}
</script>