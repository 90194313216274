import Vue from 'vue'

const state = {
	
	artForSaleSearchTerm: '',
	artForSaleCategories: [],
	activeArtForSaleCategories: [],
	searchedArtForSale: [],
	initialArtForSale: [],
	filteredArtForSale: [],
	paginatedArtForSale: [],
	currentPage: 0,
	storesPerPage: 5,
	artForSaleSortDirection: 'DESC'
	
};

const getters = {
	
	activeArtForSaleFiltersBool(state) {
		
		return (state.searchedArtForSale.length > 0 || state.activeArtForSaleCategories.length > 0)
		
	},
	
	getActiveArtForSaleFilters(state) {
		
		let {artForSaleSearchTerm, activeArtForSaleCategories} = state;
		
		return {artForSaleSearchTerm, activeArtForSaleCategories};
		
	},
	
	getArtForSalePageCount(state) {
	
		return Math.ceil(state.filteredArtForSale.length / state.storesPerPage);
	
	}
	
};

const actions = {
	
	setInitialArtForSale({commit, dispatch}, artForSales) {
		commit("initialArtForSale", artForSales);
		dispatch("filterArtForSale");
	},
	
	setCurrentPage({commit, dispatch}, page) {
		commit('currentPage', page);
		dispatch("filterArtForSale");
	},
	
	setFeaturedArtForSale({commit}, artForSale) {
		commit("featuredArtForSale", artForSale);
	},
	
	filterArtForSale({commit, state, dispatch}) {
		
		// Use initial set or searched set
		let artForSales = [...state.initialArtForSale];
		
		if(state.artForSaleSearchTerm && state.searchedArtForSale) {
			artForSales = [...state.searchedArtForSale];
		}
		
		// Category selections
		let filteredArtForSale = artForSales.filter((artForSale) => {
			
			return (artForSale.categories.filter((cat) => {
				if(!('name' in cat)) return false; // catch if there are no categories on the artForSale
				return state.activeArtForSaleCategories.includes(cat.name) // otherwise look for matches
			}).length || !state.activeArtForSaleCategories.length);
			
		});
		
		// Sorting (newest is default)
		if(state.artForSaleSortDirection === 'oldest') {
			filteredArtForSale = filteredArtForSale.sort((a, b) => new Vue.moment(a.created_at).unix() - new Vue.moment(b.created_at).unix());
		} else if(state.artForSaleSortDirection === 'a-z') {
			filteredArtForSale = filteredArtForSale.sort((a, b) => a.title.localeCompare(b.title));
		} else if(state.artForSaleSortDirection === 'z-a') {
			filteredArtForSale = filteredArtForSale.sort((a, b) => b.title.localeCompare(a.title));
		}
		
		commit("filteredArtForSale", filteredArtForSale);
		dispatch("setPaginatedArtForSale", filteredArtForSale);
		
	},
	
	setPaginatedArtForSale({commit, state}, artForSales) {
		
		let paginatedArtForSale = artForSales.slice(state.currentPage * state.storesPerPage, state.currentPage * state.storesPerPage + state.storesPerPage);
		
		commit("paginatedArtForSale", paginatedArtForSale);
		
	},
	
	setArtForSaleSearchTerm({commit}, term) {
		commit('artForSaleSearchTerm', term);
	},
	
	setSearchedArtForSale({commit, dispatch}, artForSales) {
		commit("searchedArtForSale", artForSales);
		dispatch("filterArtForSale");
	},
	
	clearArtForSaleSearch({commit, dispatch}) {
		commit("searchedArtForSale", []);
		commit("artForSaleSearchTerm", "");
		dispatch("filterArtForSale");
	},
	
	setArtForSaleCategories({commit}, categories) {
		commit("artForSaleCategories", categories);
	},
	
	setArtForSaleSortDirection({commit, dispatch}, direction) {
		commit("artForSaleSortDirection", direction)
		dispatch("filterArtForSale");
	},
	
	setActiveArtForSaleCategories({commit, dispatch, state}, payload) {
		let {type, data} = payload;
		commit(type, data);
		dispatch('filterArtForSale');
	},
	
	clearActiveArtForSaleCategories({commit, dispatch}) {
		commit('activeArtForSaleCategories', []);
		dispatch('filterArtForSale');
	},
	
	clearAllArtForSaleFilters({commit, dispatch}) {
		commit('activeArtForSaleCategories', []);
		dispatch('filterArtForSale');
	}
	
};

const mutations = {
	initialArtForSale(state, data) {
		return state.initialArtForSale = data;
	},
	currentPage(state, data) {
		return state.currentPage = data;
	},
	paginatedArtForSale(state, data) {
		return state.paginatedArtForSale = data;
	},
	filteredArtForSale(state, data) {
		return state.filteredArtForSale = data;
	},
	searchedArtForSale(state, data) {
		return state.searchedArtForSale = data;
	},
	artForSaleSearchTerm(state, data) {
		return state.artForSaleSearchTerm = data;
	},
	artForSaleCategories(state, data) {
		return state.artForSaleCategories = data;
	},
	activeArtForSaleCategories(state, data) {
		return state.activeArtForSaleCategories = data;
	},
	artForSaleSortDirection(state, data) {
		return state.artForSaleSortDirection = data;
	}
};

export default {
	state,
	mutations,
	getters,
	actions
}