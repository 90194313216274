/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

/**
 * This handles the main menu slide
 */
$(document).on('click', '.hamburger', function() {
    
    $('#NavContainer').slideToggle();
    
    $(this).toggleClass('is-active');

    let isOpen = $(this).hasClass('is-active');
    $(this).attr("aria-expanded", isOpen);
    
});

// Sticky nav
let atTop = true;

$(window).scroll(function(){
    if($(this).scrollTop()>=1 && atTop) {
        atTop = false;
        $('body').addClass('has-scrolled');
    } else if($(this).scrollTop() < 1) {
        atTop = true;
        $('body').removeClass('has-scrolled');
    }
});

// Smooth scroll anchor links
$(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();
    
    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);
});

/**
 * This handles the menu accordions
 */
$(document).on('click', '.has-items > button', function() {
    let parent = $(this).parent('li');
    parent.toggleClass('open');
    parent.children('ul').slideToggle();
});

/**
 * This function adds a class to the body if the user is a keyboard user.
 * @param e
 */
function handleFirstTab(e) {
    if (e.keyCode === 9) {
        document.body.classList.add('user-is-tabbing');
        window.removeEventListener('keydown', handleFirstTab);
    }
}

window.addEventListener('keydown', handleFirstTab);

/**
 * Start the Vue import for the site
 */
window.Vue = require('vue');
const VueMoment = require('vue-moment');

import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueMoment); // helper for JS dates and timezones
Vue.use(VueGoogleMaps, {
    load: {
        key: `${process.env.MIX_GOOGLE_API_KEY}`,
        libraries: 'places'
    }
});

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().artworks(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


Vue.component('map-component', require('./components/artworks/Map.vue').default);

Vue.component('artworks-component', require('./components/artworks/Main').default);

Vue.component('favourites-component', require('./components/favourites/MyFavouritesPage.vue').default);

Vue.component('read-only-route', require('./components/plan-a-route/ReadOnlyRoute.vue').default);

Vue.component('events-page', require('./components/events/EventsPage.vue').default);

Vue.component('artwork-cta', require('./components/artworks/Cta.vue').default);

Vue.component('resources-listing-component', require('./components/resources/ResourceLibrary').default);

Vue.component('gallery-component', require('./components/flex/Gallery').default);

Vue.component('gallery-lightbox-component', require('./components/flex/GalleryLightbox').default);

Vue.component('accordion-component', require('./components/flex/Accordion').default);

Vue.component('event-carousel', require('./components/events/EventCarousel').default);

Vue.component('announcements', require('./components/global/Announcements').default);

Vue.component('artworks-list', require('./components/artworks/single-page/RelatedListing').default);

Vue.component('favourite-button', require('./components/favourites/FavouriteButtonWrapper').default);

Vue.component('artwork-location', require('./components/artworks/single-page/Location').default);

Vue.component('translate-select', require('./components/global/Translate').default);

Vue.component('hero-video', require('./components/flex/HeroVideo').default);

Vue.component('favourites-nav', require('./components/global/FavouritesNav').default);

Vue.component('back-to-top', require('./components/global/BackToTop').default);

Vue.component('favourites-onboarding', require('./components/favourites/Onboarding').default);

Vue.component('blog-index', require('./components/blog/BlogIndex').default);

Vue.component('related-blogs', require('./components/blog/BlogList').default);

Vue.component('resource-carousel', require('./components/resources/ResourceCarousel.vue').default);

Vue.component('add-to-calendar', require('./components/events/CalendarLink.vue').default);

Vue.component('artwork-carousel', require('./components/artworks/ArtworkCarousel.vue').default);

Vue.component('art-for-sale', require('./components/art-for-sale/ArtForSalePage.vue').default);

Vue.component('tour-listing', require('./components/tours/TourListing.vue').default);

Vue.component('image-carousel', require('./components/global/ImageCarousel.vue').default);



/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import VueRouter from 'vue-router';
import { routes } from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes
});

// Vuex store
import store from './store';

const app = new Vue({
    el: '#app',
    router,
    store // vuex
});