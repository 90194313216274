<template>
  <section v-if="parsedItems.length" class="item-carousel announcements">
    <div class="container">

      <div class="carousel-control">

        <h2 id="carousel-heading">What’s New</h2>

        <div class="controls" v-if="parsedItems.length > 1">
          <button ref="previous" v-if="currentSlide > 1" @click.prevent="slidePrev" aria-label="Previous Announcement">
            <img :src="require('../../assets/images/arrows/Left.svg')" alt="">
          </button>
          <p class="item-count">{{ currentSlide }} of {{ parsedItems.length }}</p>
          <button ref="next" v-if="currentSlide < parsedItems.length" @click.prevent="slideNext"
                  aria-label="Next Announcement">
            <img :src="require('../../assets/images/arrows/Right.svg')" alt="">
          </button>
        </div>

      </div>

      <hooper
          :settings='{
          transition: 600,
          wheelControl: false,
          shortDrag: false,
          infiniteScroll: false
        }'
          ref="carousel"
          @slide="updateCount"
      >

        <slide v-for="item in parsedItems" :key="item.id">

          <div :class="['announcement', item.model]">

            <div v-if="item.image" class="image-container">
              <img :src="item.images.medium.url" :alt="`Image for ${item.title}`"/>
            </div>

            <div :class="['content', (item.model === 'press' && item.type) ? item.type.name : '']">
              <h3>{{ item.title }}</h3>

              <p class="publish-date" v-if="item.model === 'press'">
                <strong>Publish Date: {{ getDateString(item.date) }}</strong>
              </p>

              <div class="event-sessions" v-if="item.model === 'event'">
                <span class="sr-only">Event Date and Time(s): </span>
                <p v-if="item.formatted_future_sessions" v-for="session in item.formatted_future_sessions" :key="session">{{ session }}</p>
              </div>

              <p v-if="item.excerpt">{{ item.excerpt }}</p>
              <a :href="getUrl(item)" target="_blank" data-wt_params="WT.ihtml=Learn More"
                 :aria-label="`Learn more about ${item.title}`">Learn More</a>
            </div>

          </div>

        </slide>

      </hooper>

    </div>
  </section>
</template>

<script>
import {Hooper, Slide, Pagination as HooperPagination} from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: 'Announcements',
  props: ['items'],
  components: {Hooper, Slide, HooperPagination},
  data() {
    return {
      parsedItems: [],
      currentSlide: 1
    }
  },
  mounted() {
    this.parsedItems = JSON.parse(this.items);
    this.updateAria();
  },
  computed: {
    hasSessions() {
      // return ('sessions' in this.item) && this.item.sessions.length > 0;
      return false;
    }
  },
  methods: {
    slidePrev() {
      this.$refs.carousel.slidePrev();

      if (this.currentSlide === 1) {
        this.reFocus('next');
      }
    },
    slideNext() {
      this.$refs.carousel.slideNext();

      if (this.currentSlide >= this.parsedItems.length) {
        this.reFocus('previous');
      }
    },
    reFocus(arrow) {
      this.$refs[arrow].focus();
    },
    getUrl(item) {
      if (item.model === 'event') {
        return `/event/${item.slug}`
      } else if (item.model === 'press') {
        return item.url;
      } else if (item.model === 'resource') {
        return `/on-demand/${item.slug}`;
      }
    },
    updateAria() {
      this.$nextTick(() => {
        let slides = this.$refs.carousel.$children;

        slides.forEach((slide) => {
          slide.$el.removeAttribute('aria-hidden')
        });
      });
    },
    updateCount(payload) {
      this.currentSlide = payload.currentSlide + 1;
      this.updateAria();
    },
    getDateString(date) {
      let parsedDate = new Date(date);
      return parsedDate.toLocaleDateString('en-us', {year: "numeric", month: "long", day: "numeric"});
    }
  }
}
</script>

<style>
@import '~hooper/dist/hooper.css';

.hooper {
  height: auto;
}
</style>