<template>
  <div class="filters-section">

    <FiltersWrapper
        @reset="clearAll"
        :label="`Filter On Demand (by category or hub)`"
    >

      <MultiSelectFilter
          :options="types"
          dispatch-action="setActiveOnDemandTypes"
          :state-scope="stateScope"
          :filter-type="`activeOnDemandTypes`"
          :state-name="`activeTypes`"
          :heading="`Categories`"/>

      <MultiSelectFilter
          :options="hubs"
          dispatch-action="setActiveOnDemandHubs"
          :state-scope="stateScope"
          :filter-type="`activeOnDemandHubs`"
          :state-name="`activeHubs`"
          :heading="`Community Cultural Hubs`" />

    </FiltersWrapper>

    <div class="on-demand-filter-message-container">

      <div class="filter-message-container">
        <p class="filter-message" role="status" aria-live="polite" aria-atomic="true">
          Found {{ filteredOnDemand.length }} on demand resources {{ activeFilters }}</p>
        <button class="no-style clear-button" @click="clearAll()">Reset All</button>
      </div>

    </div>

  </div>
</template>

<script>
import MultiSelectFilter from "../global/filters/MultiSelectFilter";
import {mapState} from "vuex";
import FiltersWrapper from "../global/filters/FiltersWrapper";

export default {
  components: {FiltersWrapper, MultiSelectFilter},
  name: 'OnDemandFilters',
  data() {
    return {
      accordionState: false,
      stateScope: 'onDemand',
    }
  },
  mounted() {
    this.getTypes();
    this.getHubs();
  },
  computed: {
    ...mapState({
      types: state => state.onDemand.types,
      hubs: state => state.onDemand.hubs,
      filteredOnDemand: state => state.onDemand.filteredOnDemand
    }),
    accordionSign() {
      return this.accordionState ? '–' : '+';
    },
    activeFilters() {

      let text = '';

      for (const [key, value] of Object.entries(this.$store.getters.getActiveOnDemandFilters)) {

        if (value.length > 0) {

          if ((typeof value) == 'object') {
            value.forEach((item) => {
              text += (item + ', ');
            })
          } else if ((typeof value) == 'string') {
            text += (value + ', ');
          }

        }

      }

      if (text.length) {
        text = `in ${text}`.trim().slice(0, -1);
      }

      return text;
    }
  },
  methods: {
    getTypes() {

      axios
          .get('/api/on-demand/types')
          .then(response => {

            if (response.data.length) {
              this.$store.dispatch('setOnDemandTypes', response.data)
            }

          });

    },
    getHubs() {

      axios
          .get('/api/hubs')
          .then(response => {

            if (response.data.length) {
              this.$store.dispatch('setOnDemandHubs', response.data)
            }

          });

    },
    clearAll() {
      this.$store.dispatch("clearAllOnDemandFilters");
    },
    toggleAccordion() {
      this.accordionState = !this.accordionState;
    },
    resetFilters() {
      this.$store.dispatch("clearAllOnDemandFilters");
    },
  }
}
</script>

<style lang="scss">
.view-results-button {
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.2px;
  text-align: center;
  padding: 10px;
  margin-left: 10px;
  margin-bottom: 10px;

  &:after {
    background: black;
    z-index: -1;
  }
}

.filter-buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
</style>
