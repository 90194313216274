<template>
  <div class="container vue-accordion">
    <div class="accordion-item" v-if="parsedItems" v-for="(item, n) in parsedItems">

      <div class="accordion-header" @click="toggleAccordion(n)">
        <h3>
          <button :ref="`accordion-header-${n}`" :aria-expanded="String(isOpen(n))" :aria-controls="`accordion-content-${n}`">
            {{ item.attributes.heading }}<span>{{ getSign(n) }}</span>
          </button>
        </h3>
      </div>

      <slide-y-up-transition>
        <div v-show="isOpen(n)" :id="`accordion-content-${n}`" class="accordion-content">
          <div v-html="item.attributes.content"></div>
          <button class="no-style close-button" @click="toggleAccordion(n)">close</button>
        </div>
      </slide-y-up-transition>

    </div>

  </div>
</template>

<script>
import {SlideYUpTransition} from 'vue2-transitions';

  export default {
    name: 'Accordion',
    props: ['items'],
    components: {
      SlideYUpTransition
    },
    data() {
      return {
        parsedItems: [],
        openAccordions: []
      }
    },
    computed: {

    },
    mounted() {
      this.parsedItems = JSON.parse(this.items);
      this.setStartStates();
    },
    methods: {
      setStartStates() {
        this.parsedItems.forEach((item, index) => {
          if(item.attributes.start_open) {
            this.openAccordions.push(index);
          }
        })
      },
      toggleAccordion(index) {
        if(this.openAccordions.includes(index)) {
          let i = this.openAccordions.indexOf(index);
          if (i >= 0) {
            this.$refs[`accordion-header-${index}`][0].focus();
            this.openAccordions.splice( i, 1 );
          }
        } else {
          this.openAccordions.push(index);
        }
      },
      isOpen(index) {
        return this.openAccordions.includes(index);
      },
      getSign(index) {
        return this.isOpen(index) ? '–' : '+';
      },
    }
  }
</script>