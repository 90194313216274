<template>
	<div class="view-route-container">
		
		<div class="route-details-container">
			
			<div class="route-details-heading">
				<h2>{{ heading }}</h2>
				
				<div class="total-route-info" v-if="legs">
					<TotalDistance />
					<TotalTime />
				</div>
				
				<StartingLocation v-if="startingLocation" />
			
			</div>
			
			<div class="route-details">
				<RouteOrderEdit v-if="editMode" />
				<RouteDirections v-else />
				<RouteInstructions />
			</div>

		</div>
		
		<RouteMap />
		
	</div>
</template>

<script>

import {mapState} from "vuex";
import RouteOrderEdit from "./RouteOrderEdit";
import RouteDirections from "./RouteDirections";
import RouteMap from "./RouteMap";
import TotalDistance from "./TotalDistance";
import TotalTime from "./TotalTime";
import StartingLocation from "./StartingLocation";
import RouteInstructions from "./RouteInstructions";


export default {
	name: "ViewRoute",
	components: {RouteInstructions, StartingLocation, TotalTime, TotalDistance, RouteMap, RouteDirections, RouteOrderEdit},
	computed: {
		...mapState({
			startingLocation: state => state.planRoute.startingLocation,
			editMode: state => state.planRoute.editMode,
			legs: state => state.planRoute.legs
		}),
		heading() {
			return this.editMode ? 'Edit Your Route' : 'Your Route Details';
		},
	},
	beforeMount() {
		
		if (!this.$store.getters.getIsStartingAddressValid) {
			this.$router.push({path: '/favourites/plan-a-route/select-location'})
		}

	}
}
</script>