import PlanARouteRoot from "./components/plan-a-route/PlanARouteRoot";
import MyArtworkList from "./components/favourites/MyFavouritesList";
import SelectArtworks from "./components/plan-a-route/SelectArtworks";
import SelectLocation from "./components/plan-a-route/SelectLocation";
import ViewRoute from "./components/plan-a-route/ViewRoute";
import EmailMyRouteRoot from "./components/plan-a-route/EmailMyRouteRoot";
import Intro from "./components/plan-a-route/Intro";

export const routes = [
	{
		path: '/favourites',
		component: MyArtworkList,
	},
	{
		path: '/favourites/plan-a-route',
		component: PlanARouteRoot,
		children: [
			{
				path: '',
				component: Intro
			},
			{
				path: '/favourites/plan-a-route/select-artworks',
				component: SelectArtworks
			},
			{
				path: '/favourites/plan-a-route/select-location',
				component: SelectLocation
			},
			{
				path: '/favourites/plan-a-route/view-route',
				component: ViewRoute
			}
		]
	},
	{
		path: '/favourites/email-my-route',
		component: EmailMyRouteRoot
	}
];