<template>
  <div class="art-for-sale-card">

    <div v-if="imageURL" class="store-card-image">
      <img :src="imageURL" :alt="store.alt_text" />
    </div>

    <div class="details">
      <h3>{{ store.title }}</h3>
      <h4>{{ store.artist }}</h4>
      <p>{{ store.description }}</p>
      <div class="location">
        {{ store.location }}
      </div>

      <div class="store-categories" v-if="store.categories && store.categories.length > 0">
        <span class="sr-only">Categories: </span>
        <ul>
          <li v-for="category in store.categories">
            {{ category.name }}
          </li>
        </ul>
      </div>

      <a :href='store.url' target="_blank">Visit site <img src="/images/right-arrow.svg" alt="" /></a>
    </div>

  </div>
</template>

<script>
export default {
  name: "ArtForSaleCard",
  props: ['store'],
  computed: {
    imageURL() {

      if(!('images' in this.store)) {
        return false;
      }

      if(!Object.keys(this.store.images).length) {
        return false;
      }

      return this.store.images.thumb.url;

    }
  }
}
</script>