<template>
  <div :class="['resource-card', resource.image ? 'with-image' : 'without-image', `media-type-${content.type}`]">

    <div v-if="showImage" class="image-container">
      <img :src="resource.images.thumb.url" :alt="`Image of ${resource.title}`" />
    </div>
		
		<div class="embed-container" v-if="content.type === 'embed'">
			<div class="embed" v-html="content.content"></div>
		</div>
		
		<div class="self-hosted-video" v-if="content.type === 'video'">
			<video controls preload="none" :poster="`${disks.flexible}/flexible/${content.image}`">
				<source :src="`${disks.flexible}/${content.content}`"  type="video/mp4">
				Your browser does not support the <code>video</code> element.
			</video>
		</div>

    <p class="type" v-if="resource.type"><span class="sr-only">Content Type: </span><span>{{ resource.type.name }}</span></p>

    <h2>
			
			<a v-if="resource.external" class="external-link" target="_blank" :href="resource.url" :aria-label="`View the ${resource.title} resource`" >{{ resource.title }} <span class="sr-only">Link opens in new tab</span></a>
			
			<a v-else class="internal-link" :href="`/on-demand/${resource.slug}`" :aria-label="`View the ${resource.title} resource`" >{{ resource.title }}</a>
			
		</h2>

    <p class="excerpt">{{ resource.excerpt }}</p>
	
		<div class="self-hosted-audio" v-if="content.type === 'audio'">
			<h3>Listen here:</h3>
			<audio controls preload="none">
				<source :src="`${disks.flexible}/${content.content}`" type="audio/mp3">
				Your browser does not support the <code>audio</code> element.
			</audio>
		</div>

    <p class="credit" v-if="resource.credit">{{ resource.credit }}</p>

  </div>
</template>

<script>
import {disks} from "../../mixins/disks";

export default {
  name: 'ResourceCard',
  props: ['resource', 'mediaType'],
	mixins: [disks],
	computed: {
  	content() {
			if(this.resource.content.length > 0 && !this.resource.external) {
			
				let obj = this.resource.content[0];
				
				if('audio_file' in obj) {
					return {type: 'audio', content: obj.audio_file};
				} else if('video_file' in obj) {
					return {type: 'video', content: obj.video_file, image: obj.image};
				} else if('embed_code' in obj) {
					return {type: 'embed', content: obj.embed_code};
				} else if('document_file' in obj) {
					return {type: 'document', content: obj.document_file};
				}
			
			}
		
			return {type: 'external', content: this.resource.url};
		},
		showImage() {
			return (this.content.type === 'external' || this.content.type === 'audio' || this.content.type === 'document') && this.resource.image;
		},
	}
}
</script>