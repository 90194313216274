<template>
  <div class="map-listing">

      <div class="container">

        <div class="map-listing-container">
          <div class="item" v-for="artwork in slicedArtworks" :key="artwork.id">
            <Card :artwork="artwork" :is-external="isExternal" />
          </div>
        </div>

      </div>

      <div class="load-more" v-if="filteredArtworks.length > computedIncrement && currentIncrement < filteredArtworks.length">
        <button class="styled-button reversed" @click="loadMore">+ Load More</button>
      </div>

  </div>
</template>

<script>
  import Card from "./cards/Card";
  import {mapState} from "vuex";

  export default {
    name: 'ArtworkList',
    components: {Card},
    props: ['artworks', 'increment', 'isExternal'],
    data() {
      return {
        defaultIncrement: 12,
        currentIncrement: null
      }
    },
    mounted() {
      this.currentIncrement = this.computedIncrement;
    },
    computed: {
      ...mapState({
				filteredArtworks: state => state.artworks.filteredArtworks
			}),
      computedIncrement() {
        if(this.increment) {
          return parseInt(this.increment, 10);
        }

        return this.defaultIncrement;
      },
      computedCurrentIncrement() {
        if(this.artworks.length < this.currentIncrement) {
          return this.filteredArtworks.length;
        }

        return this.currentIncrement;
      },
      slicedArtworks() {
        return this.filteredArtworks.slice(0, this.currentIncrement);
      }
    },
    methods: {
      loadMore() {
        this.currentIncrement += this.computedIncrement;
      }
    }
  }
</script>
