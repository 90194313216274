<template>
  <div id="InfoWindow">

    <div class="close-bar" @click="handleClose"><button>close x</button></div>

    <div class="inner-window">
      <InfoWindowHTML ref="html" />
    </div>

  </div>
</template>

<script>


import InfoWindowHTML from "./cards/InfoWindowHTML";
export default {
  name: 'MapInfoWindow',
  components: {InfoWindowHTML},
	methods: {
    handleClose() {
      this.$emit('close');
    },
  }
}
</script>