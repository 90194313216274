<template>
  <div class="learn-more-artwork">

    <a :href="`/artwork/${slug}`" data-wt_params="WT.ihtml=Learn More" :aria-label="`Learn More about ${name}`">
      Learn More
    </a>

  </div>
</template>

<script>

export default {
  name: 'LearnMoreButton',
  props: ['slug', 'name'],
}
</script>

