<template>
	<div class="favourites-onboarding" v-show="showOnboarding">
			<div class="modal-container">
				<div class="modal-header">
					<button class="no-style" @click="dismiss()">Dismiss &#x2715;</button>
				</div>
				<div class="modal-body">
					<hooper
						:settings='{
          transition: 600,
          wheelControl: false,
          shortDrag: false,
          infiniteScroll: false,
          trimWhiteSpace: true,
        }'
						ref="carousel"
						@slide="updateAria"
					>
						
						<slide>
							<img :src="require('../../assets/images/icon-onboarding-heart.svg')" alt="" />
							<h3>All your Artworks, in one place!</h3>
							<p>You can access and manage your Artworks favourites at any time at the top of your screen by clicking on "My Favourites"</p>
						</slide>
						
						<slide>
							<img :src="require('../../assets/images/icon-onboarding-email.svg')" alt="" />
							<h3>Email your Favourites List</h3>
							<p>You will be able to save your Favourites list for later by entering your email address and having your list sent directly to your inbox.</p>
						</slide>
						
						<slide>
							<img :src="require('../../assets/images/icon-onboarding-route.svg')" alt="" />
							<h3>Plan a Custom Route</h3>
							<p>You can use the Artworks in your Favourites list to plan and create a customized route with directions to each artwork location!</p>
						</slide>
						
						<hooper-pagination slot="hooper-addons"></hooper-pagination>
					
					</hooper>
					
					<div class="controls">
						<button class="no-style" v-show="currentSlide > 0" @click.prevent="slidePrev">
							&#9666; Previous
						</button>
						<button class="no-style" v-show="currentSlide < 2" @click.prevent="slideNext">
							Next &#9656;
						</button>
						<button class="no-style finish-button" v-show="currentSlide === 2" @click="dismiss">Finish</button>
					</div>
					
				</div>
				<div class="modal-footer">
					<a href="/favourites" target="_blank">Go to My Favourites</a>
				</div>
			</div>
	</div>
</template>

<script>
import { Hooper, Slide, Pagination as HooperPagination, Navigation as HooperNavigation } from 'hooper';
import 'hooper/dist/hooper.css';
import EventCard from "../events/EventCard";
import {mapState} from "vuex";

export default {
	name: "Onboarding",
	components: {EventCard, Hooper, Slide, HooperPagination, HooperNavigation},
	data() {
		return {
			currentSlide: 0
		}
	},
	computed: {
		...mapState({
			filteredArtworks: state => state.artworks.filteredArtworks,
			showOnboarding: state => state.favourites.showOnboarding
		}),
	},
	watch: {
		showOnboarding() {
			this.$nextTick(() => {
				this.$refs.carousel.update();
			});
		}
	},
	methods: {
		dismiss() {
			this.$store.commit('showOnboarding', false);

			if(window.dcsMultiTrack) {
        window.dcsMultiTrack('WT.ihtml', 'Finished onboarding');
      }

    },
		updateAria() {
			this.$nextTick(() => {
				
				if(this.$refs.carousel) {
					
					let slides = this.$refs.carousel.$children;
					
					slides.forEach((slide) => {
						slide.$el.removeAttribute('aria-hidden')
					});
					
				}
				
			});
		},
		slidePrev() {
			this.$refs.carousel.slidePrev();
			this.currentSlide--;
		},
		slideNext() {
			this.$refs.carousel.slideNext();
			this.currentSlide++;

      if(window.dcsMultiTrack) {
        window.dcsMultiTrack('WT.ihtml', 'Next onboarding slide');
      }

		},
	}
}
</script>

<style lang="scss">
	.favourites-onboarding {
		position: fixed;
		inset: 0;
		background-color: rgba(0,0,0,0.3);
		width: 100%;
		height: 100%;
		z-index: 100;
		pointer-events: none;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.modal-container {
		background: black;
		width: 100%;
		max-width: 360px;
		height: auto;
		border: 1px solid white;
		box-shadow: 0 0 10px rgba(white, 0.4);
		pointer-events: auto;
		display: flex;
		flex-direction: column;
		color: white;
		button {
			text-decoration: none;
		}
		a, button {
			color: white;
			
		}
		.modal-body {
			padding: 25px 35px 20px 35px;
			h3 {
				font-size: 24px;
			}
			p {
				font-size: 18px;
				margin-bottom: 40px;
			}
			img {
				margin-bottom: 15px;
			}
			.controls {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				button {
					margin-left: 15px;
				}
			}
			
		}
		.modal-header {
			justify-content: flex-end;
			button {
				font-size: 12px;
			}
		}
		.modal-footer {
			justify-content: center;
			a {
				text-decoration: underline;
				&:hover {
					color: white;
					text-decoration: none;
				}
			}
		}
		.hooper-pagination {
			position: absolute;
			bottom: -20px;
			right: auto;
			transform: none;
			display: flex;
			padding: 0;
			button {
				width: 10px;
				height: 10px;
				margin-right: 7px;
				background: transparent;
				border: 1px solid white;
				&.is-active {
					background: white;
				}
			}
		}
		.finish-button {
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
</style>