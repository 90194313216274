<template>

  <div class="container event-filters">

    <div>

      <h2>Browse Events</h2>

      <div class="blog-search-container">

        <SearchBar
            clear-dispatch-action="clearEventSearch"
            results-dispatch-action="setSearchedEvents"
            label="Search events"
            endpoint="/api/events/search"
            term-dispatch-action="setEventSearchTerm" />

      </div>

      <FiltersWrapper
          @reset="resetFilters"
          :label="`Filter Events <span>(by category or date)</span>`"
      >

        <DateFilter :allow-past="showPast" />

        <MultiSelectFilter
            :options="getOptions('categories')"
            :filter-type="`categories`"
            dispatch-action="setCategoryFilter"
            state-scope="events"
            :show-diamonds="true"
            :heading="`Event Categories`"/>

      </FiltersWrapper>

      <div class="results-container">

        <p class="results">Showing {{ $store.state.events.filteredEvents.length }} results {{ activeFilters }}</p>

        <div class="show-past-input">
          <input v-model="showPast" type="checkbox" value="show" id="show-past">
          <label for="show-past">Show Past Events</label>
        </div>

      </div>

    </div>

  </div>

</template>

<script>
import FiltersWrapper from "../global/filters/FiltersWrapper";
import DateFilter from "../global/filters/DateFilter";
import {mapState} from "vuex";
import MultiSelectFilter from "../global/filters/MultiSelectFilter";
import SearchBar from "../global/filters/SearchBar";

export default {
  name: "EventFilters",
  components: {MultiSelectFilter, FiltersWrapper, DateFilter, SearchBar},
  computed: {
    ...mapState({
      options: state => state.events.options,
    }),
    showPast: {
      set(value) {
        this.$store.dispatch("setShowPastEvents", value);
      },
      get() {
        return this.$store.state.events['showPastEvents'];
      }
    },
    activeFilters() {

      let text = '';

      for (const [key, value] of Object.entries(this.$store.getters.getActiveEventFilters)) {

        if (value.length > 0) {

          if ((typeof value) == 'object') {
            value.forEach((item) => {
              text += (item + ', ');
            })
          } else if ((typeof value) == 'string') {
            text += (value + ', ');
          }

        }

      }

      if (text.length) {
        text = `for ${text}`.trim().slice(0, -1);
      }

      return text;
    }
  },
  methods: {
    getOptions(type) {
      return this.options[type].map(cat => cat.name);
    },
    resetFilters() {
      this.$store.dispatch("clearEventFilters");
    },
  }
}
</script>