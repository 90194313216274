<template>
	<section v-if="parsedResources.length" class="item-carousel resource-carousel">
		<div class="container">
			
			<div class="carousel-control">
				
				<h2 v-if="heading" id="carousel-heading">{{ heading }}</h2>
        <div class="intro" v-if="intro" v-html="parsedIntro"></div>
				
				<div :class="['controls', parsedResources.length < 4 ? 'hide-on-desktop' : '']" v-if="parsedResources.length > 1">
					<button @click.prevent="slidePrev" v-show="!isPrevDisabled">
						<img :src="require('../../assets/images/arrows/Left.svg')" alt="Left Arrow">
					</button>
					<p class="item-count">{{ parsedResources.length }} resources</p>
					<button @click.prevent="slideNext" v-show="!isNextDisabled">
						<img :src="require('../../assets/images/arrows/Right.svg')" alt="Right Arrow">
					</button>
				</div>
			
			</div>
			
			<hooper
				:settings='{
          transition: 600,
          wheelControl: false,
          shortDrag: false,
          infiniteScroll: false,
          trimWhiteSpace: true,
          breakpoints: {
            1024: {
              itemsToShow: 3,
            },
            764: {
              itemsToShow: 2,
            },
            400: {
              itemsToShow: 1,
            }
          }
        }'
				ref="carousel"
				@slide="updateAria"
			>
				
				<slide v-for="resource in parsedResources" :key="resource.id">
					<ResourceCard :resource="resource" />
				</slide>
			
			</hooper>
		
		</div>
	</section>
</template>

<script>
import { Hooper, Slide, Pagination as HooperPagination } from 'hooper';
import 'hooper/dist/hooper.css';
import ResourceCard from "./ResourceCard";
import {slider} from "../../mixins/slider";

export default {
	name: 'ResourceCarousel',
	props: ['resources', 'heading', 'intro'],
	components: {ResourceCard, Hooper, Slide, HooperPagination},
  mixins: [slider],
	data() {
		return {
			parsedResources: [],
      parsedIntro: ''
		}
	},
	mounted() {
		this.parsedResources = JSON.parse(this.resources);

		if(this.intro) {
      this.parsedIntro = JSON.parse(this.intro);
    }

		this.updateAria();
	}
}
</script>

<style lang="scss">
@import "~hooper/dist/hooper.css";

.hooper {
	height: auto;
}
</style>