<template>
  <div>

    <ArtForSaleFilters />

    <div class="art-for-sale-listing">
      <ArtForSaleCard v-for="store in paginatedArtForSale" :store="store" :key="store.id" />
    </div>

    <ArtForSalePagination />

  </div>
</template>

<script>
import ArtForSaleCard from "./ArtForSaleCard";
import {mapState} from "vuex";
import ArtForSalePagination from "./ArtForSalePagination";
import ArtForSaleFilters from "./filters/Filters";

export default {
  name: "ArtForSalePage",
  components: {ArtForSaleFilters, ArtForSalePagination, ArtForSaleCard},
  props: ['artForSale'],
  mounted() {
    this.$store.dispatch("setInitialArtForSale", JSON.parse(this.artForSale));
  },
  computed: {
    ...mapState({
      paginatedArtForSale: state => state.artForSale.paginatedArtForSale
    }),
  },
}
</script>