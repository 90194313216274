<template>
	<section class="cta-container">
		<div class="cta">
			<div class="image-container">
				<img src="../../assets/images/artworks-CTA.jpg" alt="" />
			</div>
			<div class="cta-text">
				<h3>Discover public artworks near you.</h3>
				<p>Search the interactive map by title, artist, medium, year and more.</p>
				<a href="/#Artworks">Explore Artworks</a>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'CTA'
}
</script>