<template>
  <transition name="fade">
    <button class="no-style back-to-top" v-if="position[1] > 2100" @click="backToTop" aria-label="Back to Top"><img src="../../assets/images/up-arrow-rev.svg" alt="" width="20" /></button>
  </transition>
</template>

<script>
import windowScrollPosition from "../../mixins/scroll.js"

export default {
  name: "BackToTop",
  mixins: [ windowScrollPosition('position')],
  methods: {
    backToTop() {
      window.scrollTo({
        top: 60,
        left: 0,
        behavior: 'smooth'
      });
    },
  }
}

</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
