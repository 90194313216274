<template>
  <section class="featured-event">
    <div class="container">
      <h2 id="carousel-heading">Featured Event</h2>
      <EventCard :event="event" :show-description='true' />
    </div>
  </section>
</template>

<script>

import EventCard from "./EventCard";

export default {
  props: ['event'],
  components: {EventCard},
  name: 'FeaturedEvent',
  methods: {
    endDate(session) {

      let start = this.$moment(session.start_datetime);
      let end = this.$moment(session.end_datetime);

      if(start.format("MMMM Do YYYY") !== end.format("MMMM Do YYYY")) {
        return end.format("MMMM Do YYYY, h:mm A");
      }

      return end.format('h:mm A');

    }
  }
}
</script>
