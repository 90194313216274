<template>
	<div>
		
		<FavouritesIntro />
		
		<div class="container">
			
			<section id="Artworks" class="artwork-favourites">
				
				<div class="favourite-section-intro">
					
					<div>
						<h2>My Artworks</h2>
						<p class="results" v-if="filteredArtworks.length > 0">You have {{ filteredArtworks.length }} artworks saved to your favourites.</p>
						<p class="results no-results" v-else>You have not saved any artworks yet. Your saved items will appear below.</p>
					</div>
					
					<div class="plan-a-route-link">
						
						<div v-if="filteredArtworks.length > 1">
              <p>Plan a customized travel route to visit your favourited artworks!</p>
							<router-link to="/favourites/plan-a-route" class="styled-button reversed" data-wt_params="WT.ihtml=Plan a Route">Plan&nbsp;a&nbsp;Route</router-link>
						</div>
						
						<div v-else>
							<p>Add 2 or more artworks to your Favourites to plan a route</p>
							<button class="styled-button reversed" disabled>Plan&nbsp;a&nbsp;Route</button>
						</div>
						
					</div>
					
				</div>
				
				<div class="map-listing-container">
					
					<div v-if="filteredArtworks.length > 0" class="item" v-for="artwork in filteredArtworks" :key="artwork.id">
						
						<Card :pin="getPin(artwork.artwork_type)" :artwork="artwork" />
						
					</div>
					
				</div>
				
			</section>
			
			<section id="Events" class="event-favourites">
				
				<div class="favourite-section-intro">
					
					<div>
						<h2>My Events</h2>
						<p class="results" v-if="filteredArtworks.length > 0">You have {{ initialEvents.length }} events saved to your favourites.</p>
						<p class="results no-results" v-else>You have not saved any events yet. Your saved items will appear below.</p>
					</div>
					
				</div>
				
				<div class="event-card-container" v-if="initialEvents.length > 0" >
						
						<EventCard v-for="event in initialEvents" :key="event.id" :event="event" :show-description="false" />
					
				</div>
				
			</section>
			
			<div v-if="filteredArtworks.length === 0 && initialEvents.length === 0">
				<BlankCard/>
			</div>
		
		</div>
		
		<EmailFavourites v-if="filteredArtworks.length > 0 || initialEvents.length > 0"
										 :additional-data="{
			artworks: $store.state.favourites.artworks,
			events: $store.state.favourites.events
		}">
			<template v-slot:successText>
				<p><strong>Your list will be sent to the email address you provided.</strong><br>
					You can continue to save items to your list, and request to have the most updated version sent to you when you’re done.</p>
			</template>
		</EmailFavourites>
		
		<div class="link-home" v-if="filteredArtworks.length > 0">
			<h2>Want to save more artworks to your favourites?</h2>
			<a href="/#Artworks">Explore Artworks</a>
		</div>
	
	</div>
</template>

<script>

import Card from "../artworks/cards/Card";
import EmailFavourites from "./EmailFavourites";
import BlankCard from "../artworks/cards/BlankCard";

import {pins} from "../../mixins/pins";
import {mapState} from "vuex";
import EventCard from "../events/EventCard";
import FavouritesIntro from "./FavouritesIntro";

export default {
	name: 'MyArtworkList',
	components: {FavouritesIntro, EventCard, BlankCard, EmailFavourites, Card},
	mixins: [pins],
	computed: {
		...mapState({
			filteredArtworks: state => state.artworks.filteredArtworks,
			initialEvents: state => state.events.initialEvents
		}),
	}
}
</script>