<template>
	<div class="email-route-root">
		
		<div class="container">
			<div class="exit-route">
				<router-link to="/favourites" class="back-link">Return to My Favourites</router-link>
			</div>
			
			<h1>Take Your Artworks with You</h1>
			
			<div class="route-email-instructions">
				
				<div class="details">
					<h2>Want to save your custom route?</h2>
					<p>Once you're happy with your route, use the form below to e-mail yourself the map and link to the directions. After filling out the form you will be directed to a page where you can share your route with others, or view the directions in Google Maps. Please note that once the link is generated, you will no longer be able to edit your route.</p>
					
					<router-link v-if="!routeSaved" class="back-link" to="/favourites/plan-a-route/view-route">Edit My Route</router-link>
				</div>
				<div class="phone-image">
					<img src="../../assets/images/littlephone@2x.png" alt="mobile phone image" />
				</div>
				
			</div>
				
		</div>
		
		<EmailFavourites
			button-text="Email me my map"
			endpoint="/api/route-email"
			@form_success="finalizeRoute"
			:additional-data="{
			artworkSelections,
			travelMode,
			startingLocation,
			totalTime: $store.getters.getTotalTimeString,
			totalDistance: $store.getters.getTotalDistanceString,
			polyline
		}"
		>
			<template v-slot:successText>
				<p><strong>Your list will be sent to the email address you provided. You will now be redirected to your shareable route...</strong></p>
			</template>
		</EmailFavourites>
		
	</div>
	
</template>

<script>
import EmailFavourites from "../favourites/EmailFavourites";
import {mapState} from "vuex";

export default {
	name: "EmailMyRouteRoot",
	components: {EmailFavourites},
	computed: {
		...mapState({
			artworkSelections: state => state.planRoute.artworkSelections,
			travelMode: state => state.planRoute.travelMode,
			startingLocation: state => state.planRoute.startingLocation,
			polyline: state => state.planRoute.polyline,
			routeSaved: state => state.planRoute.routeSaved
		}),
		artworkSelectionIds() {
			return this.artworkSelections.map((artwork) => {
				return artwork.id
			})
		}
	},
	beforeMount() {
		if(!this.$store.getters.getCanRouteSave) {
			this.$router.push({path: "/favourites/plan-a-route/view-route"})
		}
	},
	methods: {
		finalizeRoute(payload) {
			
			this.$store.dispatch("finalizeRoute");
			
			setTimeout(() => {
				window.location = payload;
			}, 500);
			
		}
	}
}
</script>