import Vue from 'vue'

const state = {

	blogSearchTerm: '',
	blogCategories: [],
	activeBlogCategories: [],
	activeMonth: '',
	searchedBlogs: [],
	initialBlogs: [],
	filteredBlogs: [],
	featuredBlog: {},
	sortDirection: 'DESC'

};

const getters = {

	activeBlogFiltersBool(state) {
		return (state.searchedBlogs.length > 0 || state.activeBlogCategories.length > 0)
	},

	getActiveBlogFilters(state) {

		let {blogSearchTerm, activeBlogCategories, activeMonth} = state;

		return {blogSearchTerm, activeBlogCategories, activeMonth};

	}

};

const actions = {

	setInitialBlogs({commit, dispatch}, blogs) {
		commit("initialBlogs", blogs);
		commit("filteredBlogs", blogs);
	},
	
	setFeaturedBlog({commit}, blog) {
		commit("featuredBlog", blog);
	},

	filterBlogs({commit, state}) {

		let blogs = [...state.initialBlogs];

		if(state.blogSearchTerm && state.searchedBlogs) {
			blogs = [...state.searchedBlogs];
		}

		let filteredBlogs = blogs.filter((blog) => {

			let catFilter = (blog.categories.filter((cat) => {
				if(!('name' in cat)) return false; // catch if there are no categories on the blog
				return state.activeBlogCategories.includes(cat.name) // otherwise look for matches
			}).length || !state.activeBlogCategories.length);

			let published = Vue.moment(blog.publish_date).format('MMMM');
			let monthFilter = (published === state.activeMonth || state.activeMonth === '');

			return catFilter && monthFilter;

		});

		if(state.sortDirection === 'ASC') { // only need to do this when sorting ascending because desc is default
			filteredBlogs = filteredBlogs.sort((a, b) => new Vue.moment(a.publish_date).unix() - new Vue.moment(b.publish_date).unix());
		}

		commit("filteredBlogs", filteredBlogs);

	},

	setBlogSearchTerm({commit}, term) {
		commit('blogSearchTerm', term);
	},

	setSearchedBlogs({commit, dispatch}, blogs) {
		commit("searchedBlogs", blogs);
		dispatch("filterBlogs");
	},

	clearBlogSearch({commit, dispatch}) {
		commit("searchedBlogs", []);
		commit("blogSearchTerm", "");
		dispatch("filterBlogs");
	},

	setBlogCategories({commit}, categories) {
		commit("blogCategories", categories);
	},

	setActiveMonth({commit, dispatch}, payload) {
		commit("activeMonth", payload.data);
		dispatch("filterBlogs");
	},

	setSortDirection({commit, dispatch}, direction) {
		commit("sortDirection", direction)
		dispatch("filterBlogs");
	},

	setActiveBlogCategories({commit, dispatch, state}, payload) {
		let {type, data} = payload;
		commit(type, data);
		dispatch('filterBlogs');
	},

	clearActiveBlogCategories({commit, dispatch}) {
		commit('activeCategories', []);
		dispatch('filterBlogs');
	},

	clearAllBlogFilters({commit, dispatch}) {
		commit('activeBlogCategories', []);
		commit('activeMonth', '');
		dispatch('filterBlogs');
	}

};

const mutations = {
	initialBlogs(state, data) {
		return state.initialBlogs = data;
	},
	featuredBlog(state, data) {
		return state.featuredBlog = data;
	},
	filteredBlogs(state, data) {
		return state.filteredBlogs = data;
	},
	searchedBlogs(state, data) {
		return state.searchedBlogs = data;
	},
	blogSearchTerm(state, data) {
		return state.blogSearchTerm = data;
	},
	blogCategories(state, data) {
		return state.blogCategories = data;
	},
	activeMonth(state, data) {
		return state.activeMonth = data;
	},
	activeBlogCategories(state, data) {
		return state.activeBlogCategories = data;
	},
	sortDirection(state, data) {
		return state.sortDirection = data;
	}
};

export default {
	state,
	mutations,
	getters,
	actions
}