<template>
	<div class="blog-page">
		
		<!--	Filters	-->
		<BlogFilters />
		
		<!--	Featured	-->
		<BlogCard v-if="featuredBlog && !$store.getters.activeBlogFiltersBool" :blog="featuredBlog" :is-featured="true" />
		
		<!--	Listing	-->
		<div class="blog-listing">
			<BlogCard v-for="blog in slicedBlogs" :key="blog.id" :blog="blog" />
		</div>
		
		<LoadMore v-if="filteredBlogs.length > increment && currentIncrement < filteredBlogs.length" @clicked="loadMore" />
	
	</div>
</template>

<script>

import BlogCard from "./BlogCard";
import LoadMore from "../global/LoadMore";
import BlogFilters from "./filters/Filters";
import {mapState} from "vuex";

export default {
	name: "BlogIndex",
	components: {BlogFilters, LoadMore, BlogCard},
	props: ['featured', 'all'],
	data() {
		return {
			increment: 6,
			currentIncrement: 6
		}
	},
	mounted() {
		this.$store.dispatch("setFeaturedBlog", JSON.parse(this.featured));
		this.$store.dispatch("setInitialBlogs", JSON.parse(this.all))
	},
	computed: {
		...mapState({
			filteredBlogs: state => state.blog.filteredBlogs,
			featuredBlog: state => state.blog.featuredBlog
		}),
		computedIncrement() {
			if(this.filteredBlogs.length < this.currentIncrement) {
				return this.filteredBlogs.length;
			}
			
			return this.currentIncrement;
		},
		slicedBlogs() {
			return this.filteredBlogs.slice(0, this.currentIncrement);
		}
	},
	methods: {
		loadMore() {
			this.currentIncrement += this.increment;
		}
	}
}
</script>

<style >

</style>