<template>
  <div class="art-for-sale-pagination">
    <button class="pagination-button prev-button" @click="previousPage" v-show="currentPage !== 0">Previous</button>
    <ul>
      <li v-for="(n, index) in $store.getters.getArtForSalePageCount" :key="index" >
        <button :class="[index === currentPage ? 'active' : '']" @click="goToPage(index)" >{{ n }}</button>
      </li>
    </ul>
    <button class="pagination-button next-button"  @click="nextPage" v-show="currentPage !== $store.getters.getArtForSalePageCount - 1">Next</button>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ArtForSalePagination",
  computed: {
    ...mapState({
      currentPage: state => state.artForSale.currentPage
    }),
  },
  methods: {
    previousPage() {
      this.$store.dispatch("setCurrentPage", this.currentPage - 1);
    },
    nextPage() {
      this.$store.dispatch("setCurrentPage", this.currentPage + 1);
    },
    goToPage(page) {
      this.$store.dispatch("setCurrentPage", page);
    }
  },
}
</script>