var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"route-map"},[_c('GmapMap',{ref:"mapRef",attrs:{"center":_vm.center,"zoom":_vm.zoom,"options":{
            mapTypeControl: false,
            fullscreenControl: false,
            styles: _vm.mapStyles
          }}},[(_vm.finalDestination && _vm.origin)?_c('DirectionsRenderer',{attrs:{"origin":_vm.origin,"travelMode":_vm.travelMode,"waypoints":_vm.wayPoints,"destination":_vm.finalDestination},on:{"directions_changed":_vm.populateDirections}}):_vm._e(),_vm._v(" "),_vm._l((_vm.artworkSelections),function(art,n){return (_vm.google)?_c('GmapMarker',{key:art.id,ref:"markerRef",refInFor:true,attrs:{"label":{
					text: (n + 1).toString(),
					color: 'white',
					fontWeight: 'bold'
					 },"position":{lat: art.latitude, lng: art.longitude},"clickable":false,"title":_vm.getMarkerTitle(art),"icon":_vm.routePin}}):_vm._e()}),_vm._v(" "),(_vm.google && _vm.startingLocation)?_c('GmapMarker',{ref:"homeStar",attrs:{"position":_vm.startingLocation.geometry ? _vm.startingLocation.geometry.location : null,"clickable":false,"title":"Starting Location","icon":_vm.homePin}}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }