<template>
	<div class="calendar-link-container">
		<button v-if="isSupported" class="calendar-link no-style" @click="getCalendarFile">Add to my Calendar</button>
	</div>
</template>

<script>
export default {
	name: "CalendarLink",
	props: ['id'],
  data() {
    return {
      isSupported: true
    }
  },
  mounted() {
	  // Calendar link isn't supported on iOS Chrome
    if(navigator.userAgent.match('CriOS')) {
      this.isSupported = false;
    }
  },
  methods: {
		getCalendarFile() {

		  if(!this.isSupported) {
		    return;
      }
			
			let data = {
				id: this.id
			}
			
			axios
				.post('/api/calendar-file', data)
				.then(response => {
				  const reader = new FileReader();
          const blob = new Blob([response.data], { type: 'text/calendar' })

          reader.onload = (e) => {
            window.location.href = reader.result;
          }

          reader.readAsDataURL(blob);

				}).catch(console.error);
		}
	}
}
</script>