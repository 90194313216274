var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.parsedEvents.length)?_c('section',{staticClass:"item-carousel"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"carousel-control"},[_c('div',{staticClass:"heading-group"},[(_vm.heading)?_c('h2',{attrs:{"id":"carousel-heading"}},[_vm._v(_vm._s(_vm.heading))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"past-current-toggle"},[_c('button',{class:[ !_vm.showPast ? 'active' : '' ],on:{"click":function($event){return _vm.setShowPast(false)}}},[_vm._v("Show Current")]),_vm._v(" "),_c('button',{class:[ _vm.showPast ? 'active' : '' ],on:{"click":function($event){return _vm.setShowPast(true)}}},[_vm._v("Show Past")])])]),_vm._v(" "),(_vm.filteredEvents.length > 1)?_c('div',{class:['controls', _vm.filteredEvents.length < 4 ? 'hide-on-desktop' : '']},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPrevDisabled),expression:"!isPrevDisabled"}],on:{"click":function($event){$event.preventDefault();return _vm.slidePrev.apply(null, arguments)}}},[_c('img',{attrs:{"src":require('../../assets/images/arrows/Left.svg'),"alt":"Left Arrow"}})]),_vm._v(" "),_c('p',{staticClass:"item-count"},[_vm._v(_vm._s(_vm.filteredEvents.length)+" events")]),_vm._v(" "),_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isNextDisabled),expression:"!isNextDisabled"}],on:{"click":function($event){$event.preventDefault();return _vm.slideNext.apply(null, arguments)}}},[_c('img',{attrs:{"src":require('../../assets/images/arrows/Right.svg'),"alt":"Right Arrow"}})])]):_vm._e()]),_vm._v(" "),_c('hooper',{ref:"carousel",attrs:{"settings":{
        transition: 600,
        wheelControl: false,
        shortDrag: false,
        infiniteScroll: false,
        trimWhiteSpace: true,
        breakpoints: {
          1024: {
            itemsToShow: 3,
          },
          764: {
            itemsToShow: 2,
          },
          400: {
            itemsToShow: 1,
          },
        }
      }},on:{"slide":_vm.updateAria}},_vm._l((_vm.filteredEvents),function(event){return _c('slide',{key:event.id},[_c('EventCard',{attrs:{"event":event}})],1)}),1),_vm._v(" "),(_vm.filteredEvents.length === 0)?_c('div',{staticClass:"no-items"},[_c('p',[_vm._v("There are no "+_vm._s(_vm.showPast ? 'past' : 'current')+" events right now.")])]):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }