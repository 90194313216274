<template>
  <div id="ListingInfoWindow">

    <div class="inner-window">

      <div v-if="clusterArtworkList" class="cluster-listing">

        <div v-for="artwork in clusterArtworkList"
             :key="artwork.id"
             :class="['artwork-cluster-card', artwork.local_image ? 'has-image' : 'no-image']">

          <div class="details-container">

            <div class="image" v-if="artwork.local_image">
              <img :src="artwork.images.thumb.url" :alt="artwork.name + ' in situ'"/>
            </div>

            <div class="details">
              <h5 class="artwork-name"><span class="sr-only">Artwork Name: </span>{{ name(artwork) }}</h5>
              <p class="artist"><span class="sr-only">Artist: </span>{{ artist(artwork) }}</p>
            </div>

          </div>

          <button @click="selectArtwork(artwork)" class="no-style select-button stretched-link">
            <span class="sr-only">Select {{ name(artwork) }} by {{ artist(artwork) }}</span>
          </button>

        </div>

      </div>

    </div>

  </div>
</template>

<script>

import {mapState} from "vuex";
import {pins} from "../../mixins/pins";

export default {
  name: 'ListingInfoWindow',
  computed: {
    ...mapState({
      clusterArtworkList: state => state.artworks.clusterArtworkList,
    }),
  },
  mixins: [pins],
  methods: {
    name(artwork) {
      return artwork.name ? artwork.name : 'Untitled';
    },
    artist(artwork) {
      return artwork.artist ? artwork.artist : 'Unknown Artist';
    },
    selectArtwork(art) {
      this.$store.dispatch('setClusterArtworkList', []);
      this.$store.dispatch('setActiveArtwork', art);
    }
  }
}
</script>