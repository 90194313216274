<template>
  <div class="checkbox-radio-filter">
		
		<FilterContainer :label="heading" :filter-type="filterType" :group-type="`single`">
			<div class="radio" v-for="option in options" :key="option">
				<input v-model="filter" :value="option" :id="slugify(option)" type="radio" :name="radioName" />
				<label :for="slugify(option)">{{ option }}</label>
			</div>
		</FilterContainer>

  </div>
</template>

<script>
  import ClearButton from "../../artworks/buttons/ClearButton";
	import FilterContainer from "./FilterContainer";
  import {slugify} from "../../../mixins/slugify";

  export default {
    name: "SingleSelectFilter",
    components: {FilterContainer, ClearButton},
    props: ['filterType', 'options', 'heading', 'dispatchAction', 'stateScope'],
    mixins: [slugify],
    computed: {
      radioName() {
        return `radio-${this.filterType.toLowerCase().replace(' ', '-')}`;
      },
      filter: {
        set(value) {
          this.$store.dispatch(this.dispatchAction, {type: this.filterType, data: value});
        },
        get() {
          return this.$store.state[this.stateScope][this.filterType];
        }
      }
    }
  }
</script>

<style lang="scss">
  .radio-container {
    display: block;
  }
</style>