<template>
	<div class="filters-section">
		
		<div class="art-for-sale-search-container">
			
			<SearchBar
				clear-dispatch-action="clearArtForSaleSearch"
				results-dispatch-action="setSearchedArtForSale"
				label="Search stores by artist, title, or description"
				endpoint="/api/art-for-sale/search"
				term-dispatch-action="setArtForSaleSearchTerm" />

		</div>
		
		<FiltersWrapper
			@reset="clearAll"
			:label="`Art For Sale Filters`"
		>
			
			<MultiSelectFilter
				:options="artForSaleCategories"
				dispatch-action="setActiveArtForSaleCategories"
				:state-scope="stateScope"
				:filter-type="`activeArtForSaleCategories`"
				:heading="`Categories`" />
			
		</FiltersWrapper>
		
		<div class="art-for-sale-filter-message-container">
			
			<div class="filter-message-container">
				<p class="filter-message" role="status" aria-live="polite" aria-atomic="true">
					Found {{ filteredArtForSale.length }} stores {{ activeFilters }}</p>
				<button class="no-style clear-button" @click="clearAll()">Reset All</button>
			</div>

      <Sort />
			
		</div>
	
	</div>
</template>

<script>
import SingleSelectFilter from "../../global/filters/SingleSelectFilter";
import MultiSelectFilter from "../../global/filters/MultiSelectFilter";
import {mapState} from "vuex";
import Sort from "./Sort";
import FiltersWrapper from "../../global/filters/FiltersWrapper";
import SearchBar from "../../global/filters/SearchBar";

export default {
	components: {SearchBar, FiltersWrapper, Sort, MultiSelectFilter, SingleSelectFilter},
	name: 'ArtForSaleFilters',
	data() {
		return {
			accordionState: false,
			stateScope: 'artForSale',
		}
	},
	mounted() {
		this.getCategories();
	},
	computed: {
		...mapState({
      artForSaleCategories: state => state.artForSale.artForSaleCategories,
			filteredArtForSale: state => state.artForSale.filteredArtForSale
		}),
		accordionSign() {
			return this.accordionState ? '–' : '+';
		},
		activeFilters() {
			
			let text = '';
			
			for (const [key, value] of Object.entries(this.$store.getters.getActiveArtForSaleFilters)) {
				
				if(value.length > 0) {
					
					if((typeof value) == 'object') {
						value.forEach((item) => {
							text += (item + ', ');
						})
					} else if((typeof value) == 'string') {
						text += (value + ', ');
					}
					
				}
				
			}
			
			if(text.length) {
				text = `in ${text}`.trim().slice(0, -1);
			}
			
			return text;
		}
	},
	methods: {
		getCategories() {
			
			axios
				.get('/api/art-for-sale/categories')
				.then(response => {
					
					if(response.data.length) {
						this.$store.dispatch('setArtForSaleCategories', response.data)
					}
					
				});
			
		},
		clearAll() {
			this.$store.dispatch("clearAllArtForSaleFilters");
		},
		toggleAccordion() {
			this.accordionState = !this.accordionState;
		},
		resetFilters() {
			this.$store.dispatch("clearAllArtForSaleFilters");
		},
	}
}
</script>

<style lang="scss">
.view-results-button {
	font-size: 18px;
	font-weight: 900;
	letter-spacing: 0.2px;
	text-align: center;
	padding: 10px;
	margin-left: 10px;
	margin-bottom: 10px;
	&:after {
		background: black;
		z-index: -1;
	}
}
.filter-buttons {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
}
</style>
