<template>
  <section class="hub-artworks">
    <div class="container">
      <h2>{{ heading }}</h2>
    </div>
    <List :increment="increment" :artworks="filteredArtworks" :is-external=true />
  </section>
</template>

<script>
import List from "../List";
import {mapState} from "vuex";

export default {
  name: "ArtworksListing",
  props: ['artworks', 'heading', 'increment'],
  components: { List },
  data() {
    return {
      parsedArtworks: [],
    }
  },
  computed: {
    ...mapState({
			filteredArtworks: state => state.artworks.filteredArtworks
		}),
  },
  mounted() {
    this.$store.dispatch('setInitialFavourites');
    this.$store.dispatch('setInitialArtworks', JSON.parse(this.artworks));
    this.$store.dispatch("filterArtworks");
  }
}
</script>
