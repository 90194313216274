const state = {

	artworkSelections: [],
	startingLocation: {},
	travelMode: "WALKING",
	editMode: false,
	legs: [],
	polyline: "",
	routeSaved: false

};

const mutations = {

	artworkSelections(state, data) {
		return state.artworkSelections = data;
	},

	startingLocation(state, data) {
		return state.startingLocation = data;
	},

	travelMode(state, data) {
		return state.travelMode = data;
	},

	editMode(state, data) {
		return state.editMode = data;
	},

	legs(state, data) {
		return state.legs = data;
	},

	polyline(state, data) {
		return state.polyline = data;
	},

	routeSaved(state, data) {
		return state.routeSaved = data;
	}

};

const getters = {

	getTotalDistance(state) { // in meters
		return state.legs.reduce((accumulator, leg) => {
			return accumulator + leg.distance.value;
		}, 0);
	},

	getTotalDistanceString(state, getters) {
		if(!state.legs) {
			return 0;
		}

		return (getters.getTotalDistance / 1000).toFixed(2) + " km";
	},

	getTotalTime(state) { // in seconds
		return state.legs.reduce((accumulator, leg) => {
			return accumulator + leg.duration.value;
		}, 0);
	},

	getTotalTimeString(state, getters) {

		if(!state.legs) {
			return 0;
		}

		let num = getters.getTotalTime; // seconds
		let hours = ((num / 60) / 60);
		let roundHours = Math.floor(hours);
		let minutes = (hours - roundHours) * 60;
		let roundMinutes = Math.round(minutes);

		let hoursText = roundHours > 0 ? `${roundHours} hour(s) ` : '';

		return hoursText + roundMinutes + " mins";

	},

	getStartingLocation(state) {
		return state.startingLocation;
	},

	getIsStartingAddressValid(state) {
		return (('formatted_address' in state.startingLocation) && ('geometry' in state.startingLocation));
	},

	// Verify we have everything we need to save
	getCanRouteSave(state, getters) {

		return getters.getIsStartingAddressValid
			&& (state.travelMode.length > 0)
			&& (state.artworkSelections.length > 1)
			&& (state.legs.length > 0)
			&& (state.polyline.length > 0);
	}

};

const actions = {

	setSelections({commit}, payload) {
		commit("artworkSelections", payload);
	},

	setStartingLocation({commit}, payload) {
		commit("startingLocation", payload);
	},

	setTravelMode({commit}, payload) {
		commit("travelMode", payload)
	},

	finalizeRoute({commit}) {

		commit("routeSaved", true);

		commit("artworkSelections", []);
		commit("startingLocation", {});
		commit("travelMode", "");
	}

};

export default {
	state,
	mutations,
	getters,
	actions
}