<template>
  <div class="image-carousel">

    <hooper
        v-if="parsedImages.length > 1"
        :settings='{
          transition: 600,
          wheelControl: false,
          shortDrag: false,
          infiniteScroll: false,
          trimWhiteSpace: true,
          itemsToShow: 1
        }'
        ref="carousel"
    >

      <slide v-for="image in parsedImages" :key="image.original_url">
        <img :src="image.original_url" alt="" />
      </slide>

    </hooper>

    <div class="carousel-control">

      <div class="controls" v-if="parsedImages.length > 1">

        <button @click.prevent="slidePrev" aria-label="Slide Previous">
          <img v-show="!isPrevDisabled" :src="require('../../assets/images/arrows/Left.svg')" alt="">
        </button>

        <p class="item-count"><span class="sr-only">Current Slide:</span> {{ currentIndex + 1 }} / {{ parsedImages.length }}</p>

        <button @click.prevent="slideNext"  aria-label="Slide Next">
          <img v-show="!isNextDisabled" :src="require('../../assets/images/arrows/Right.svg')" alt="">
        </button>

      </div>

    </div>

  </div>
</template>

<script>
import {Hooper, Slide, Pagination as HooperPagination} from 'hooper';
import 'hooper/dist/hooper.css';
import {slider} from "../../mixins/slider";

export default {
  name: "ImageCarousel",
  components: {
    Hooper, Slide, HooperPagination
  },
  mixins: [slider],
  props: ['images'],
  data() {
    return {
      parsedImages: []
    }
  },
  mounted() {
    if(typeof(this.images) === 'string') {
      this.parsedImages = JSON.parse(this.images);
    } else {
      this.parsedImages = this.images
    }
  },
}
</script>