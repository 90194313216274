import {helpers} from "../helpers/artworx-helpers";
import {gmapApi} from 'vue2-google-maps'

const state = {
	
	boroughData: [],
	
	boroughs: [],
	artworkTypes: [],
	programs: [],
	artistBackground: "",
	hubs: [],
	searchTerm: '',
	
	artworksView: 'map',
	boroughView: false,
	
	options: {
		boroughs: [],
		artworkTypes: [],
		artistBackground: [],
		hubs: [],
		programs: []
	},
	
	searchedArtworks: [],
	initialArtworks: [],
	filteredArtworks: [],
	activeArtwork: null,
	
	clusterArtworkList: []
	
};

const mutations = {
	initialArtworks(state, data) {
		return state.initialArtworks = data;
	},
	filteredArtworks(state, data) {
		return state.filteredArtworks = data;
	},
	searchedArtworks(state, data) {
		return state.searchedArtworks = data;
	},
	searchTerm(state, data) {
		return state.searchTerm = data;
	},
	activeArtwork(state, data) {
		return state.activeArtwork = data;
	},
	boroughs(state, data) {
		return state.boroughs = data;
	},
	boroughData(state, data) {
		return state.boroughData = data;
	},
	artworkTypes(state, data) {
		return state.artworkTypes = data;
	},
	programs(state, data) {
		return state.programs = data;
	},
	hubs(state, data) {
		return state.hubs = data;
	},
	options(state, data) {
		return state.options = data;
	},
	programOptions(state, data) {
		return state.options.programs = data;
	},
	hubOptions(state, data) {
		return state.options.hubs = data;
	},
	boroughOptions(state, data) {
		return state.options.boroughs = data;
	},
	boroughView(state, data) {
		return state.boroughView = data;
	},
	artworksView(state, data) {
		return state.artworksView = data;
	},
	clusterArtworkList(state, data) {
		return state.clusterArtworkList = data;
	}
};

const getters = {
	
	getLegend(state) {
		return state.options.artworkTypes;
	},
	
	getBoroughs(state) {
		return state.boroughs;
	},
	
	getArtworkTypes(state) {
		return state.artworkTypes;
	},
	
	getHub(state) {
		return state.hub;
	},
	
	getPrograms(state) {
		return state.programs;
	},
	
	getActiveArtwork(state) {
		return state.activeArtwork;
	},
	
	getActiveFiltersBool(state) {
		return (state.artworkTypes.length > 0 || state.programs.length > 0 || state.hubs.length > 0);
	},
	
	getFilters(state) {
		
		let {boroughs, artworkTypes, programs, hubs, searchTerm} = state;
		
		return {searchTerm, artworkTypes, programs, boroughs, hubs};
		
	}
	
};

const actions = {
	
	// Initial Setup
	
	setInitialArtworks({commit, dispatch}, artworks) { // 2
		commit("initialArtworks", artworks);
		dispatch("setOptions");
	},
	
	setOptions({commit, state}) {
		
		let artworks = [...state.initialArtworks];
		
		let initialOptions = {...state.options};
		
		let options = artworks.reduce((obj, artwork) => {
			
			let {artwork_type} = artwork;
			
			return {
				artworkTypes: helpers.checkOption(obj.artworkTypes, artwork_type),
				hubs: state.options.hubs,
				programs: state.options.programs,
				boroughs: state.options.boroughs
			}
			
		}, initialOptions)
		
		commit("options", options);
		
	},
	
	filterArtworks({commit, state, dispatch}) {
		
		let artworks = [...state.initialArtworks];
		
		// we only want to filter on a different set of artworks if we have active search results
		if (state.searchTerm && state.searchedArtworks) {
			artworks = [...state.searchedArtworks];
		}
		
		let filteredArtworks = artworks;
		
		filteredArtworks = filteredArtworks.filter((artwork) => {
			return (artwork.latitude && artwork.longitude)
		});
		
		if(state.programs.length > 0) {
			filteredArtworks = filteredArtworks.filter((artwork) => {
				return (artwork.program && state.programs.includes(artwork.program.name));
			});
		}
		
		if(state.hubs.length > 0) {
			filteredArtworks = filteredArtworks.filter((artwork) => {
					return artwork.hubs.length > 0 ? state.hubs.includes(artwork.hubs[0].title) : false;
			});
		}
		
		if(state.artworkTypes.length > 0) {
			filteredArtworks = filteredArtworks.filter((artwork) => {
				return state.artworkTypes.includes(artwork.artwork_type);
			});
		}
		
		if (state.boroughs.length > 0) {
			
			let google = new gmapApi();
			
			// filter
			filteredArtworks = filteredArtworks.filter((artwork) => {
				
				let contains = false;
				
				state.boroughs.forEach((boroughName) => {
					
					let boroughData = state.boroughData.find(borough => borough.name === boroughName);
					
					if (boroughData) {
						
						// we only want to check the false ones so we don't revert a true one
						if (!contains) {
							let latLng = new google.maps.LatLng({lat: artwork.latitude, lng: artwork.longitude});
							
							boroughData.poly.forEach((area) => {
								
								if (!contains) {
									contains = google.maps.geometry.poly.containsLocation(latLng, area);
								}
								
							});
							
						}
						
					}
					
				});
				
				return contains;
				
			});
			
			google = null; // cleanup
			
		}
		
		if (state.activeArtwork) {
			
			let artwork = filteredArtworks.find(item => item.id === state.activeArtwork.id);
			
			if (!artwork) {
				commit("activeArtwork", null);
			}
			
		}
		
		commit("filteredArtworks", filteredArtworks);
		dispatch('setFilterUrl');
		
	}, //
	
	setInitialFilters({commit, dispatch, state}, params) {
		
		for (const [key, value] of Object.entries(params)) {
			
			if (key in state) {
				if (key === 'activeArtwork') {
					
					// find artwork
					let artwork = state.initialArtworks.find(item => item.id == value);
					
					if (artwork) {
						commit(key, artwork);
					}
					
				} else {
					commit(key, value);
				}
			}
			
		}
		
		dispatch('filterArtworks');
		
	},
	
	setFilterUrl({state, getters}) {
		
		let params = '';
		
		if (getters.getActiveFiltersBool || getters.getActiveArtwork) {
			
			let filters = getters.getFilters;
			
			params = '?';
			
			for (const [key, value] of Object.entries(filters)) {
				
				if (value.length > 0) {
					
					if ((typeof value) == 'object') {
						
						value.forEach((item) => {
							params += `${key}[]=${encodeURIComponent(item)}&`;
						})
						
					} else if ((typeof value) == 'string' && (key !== 'searchTerm')) {
						params += `${key}=${encodeURIComponent(value)}&`;
					}
					
				}
				
			}
			
			if (state.activeArtwork) {
				params += `activeArtwork=${state.activeArtwork.id}`
			}
			
			if(!window.location.hash) {
				params += '#map-container';
			}
			
			params = params.replace(/&$/, '')
			
		}
		
		window.history.replaceState({}, "ArtworxTO", params);
		
	},
	
	// Filter Actions
	
	populateBoroughState({commit, state}, payload) {
		
		let boroughData = [...state['boroughData'], payload];
		
		let options = [...state.options.boroughs, payload.name];
		
		commit('boroughData', boroughData);
		commit('boroughOptions', options);
		
	},
	
	setProgramState({commit, state}, payload) {
		commit('programOptions', payload);
	},
	
	setHubState({commit, state}, payload) {
		commit('hubOptions', payload);
	},
	
	setMultiFilter({commit, dispatch, state}, payload) {
		let {type, data} = payload;
		commit(type, data);
		dispatch('filterArtworks');
	},
	
	addMultiFilter({commit, dispatch, state}, payload) {
		
		let {type, data} = payload;
		
		let filters = [...state[type], data];
		
		commit(type, filters);
		dispatch('filterArtworks');
		
	},
	
	removeMultiFilter({commit, dispatch, state}, payload) {
		
		let {type, data} = payload;
		
		let filter = [...state[type]];
		
		const index = filter.indexOf(data);
		
		if (index > -1) {
			filter.splice(index, 1);
		}
		
		commit(type, filter);
		dispatch('filterArtworks');
		
	},
	
	removeAllMultiFilter({commit, dispatch}, type) {
		
		commit(type, []);
		helpers.updateScreenReaderFilterStatus(`${type} filter is cleared`);
		dispatch('filterArtworks');
		
	},
	
	setSingleFilter({commit, dispatch}, payload) {
		
		let {type, data} = payload;
		
		commit(type, data);
		
		if (data.length === 0) {
			helpers.updateScreenReaderFilterStatus(`${type} filter is cleared`);
		}
		
		dispatch('filterArtworks');
		
	},
	
	setClusterArtworkList({commit, state}, ids) {
		let artworks = state.filteredArtworks.filter(art => {
			return ids.includes(art.id);
		});
		
		commit("clusterArtworkList", artworks);
	},
	
	setActiveArtwork({commit, dispatch}, artwork) {
		commit("activeArtwork", artwork);
		dispatch('setFilterUrl');
	},
	
	setSearchTerm({commit}, term) {
		commit('searchTerm', term);
	},
	
	setSearchedArtworks({commit, dispatch, state}, artworks) {
		dispatch("setActiveArtwork", null);
		commit("searchedArtworks", artworks);
		dispatch("filterArtworks");
	},
	
	clearSearch({commit, dispatch}) {
		commit("searchedArtworks", []);
		commit("searchTerm", "");
		dispatch("filterArtworks");
	},
	
	setArtworksView({dispatch, state, commit}, view) {
		
		if (view === 'map' || view === 'grid') {
			commit("artworksView", view);
			helpers.updateScreenReaderViewStatus(`Displaying ${view} View`);
			
			if (view === 'grid') {
				dispatch("setActiveArtwork", null);
			}
			
		}
		
	},
	
	clearAll({commit, dispatch}) {
		
		commit("boroughs", []);
		commit("artworkTypes", []);
		commit("programs", []);
		commit("hubs", "");
		commit("searchTerm", "");
		commit("searchedArtworks", []);
		commit("activeArtwork", null);
		
		helpers.updateScreenReaderFilterStatus('All filters, selected artworks, and search results cleared.');
		
		dispatch("filterArtworks");
		
	},
	
	clearFilters({commit, dispatch}) {
		commit("artworkTypes", []);
		commit("programs", []);
		commit("hubs", "");
		commit("boroughs", []);
		commit("activeArtwork", null);
		
		helpers.updateScreenReaderFilterStatus('All artwork filters cleared.');
		
		dispatch("filterArtworks");
	},
	
};

export default {
	state,
	mutations,
	getters,
	actions
}