<template>
	<div class="container create-a-route-container">
		
		<div class="exit-route">
			<router-link to="/favourites" class="back-link">Return to My Favourites</router-link>
		</div>
		
		<h1>Plan a Route</h1>
		
		<router-view></router-view>
		
	</div>
</template>

<script>
import MultiSelectFilter from "../global/filters/MultiSelectFilter";

export default {
	name: "PlanARouteRoot",
	components: {MultiSelectFilter},
	mounted() {
		// We don't want them here if they don't have enough favourites
		if(this.$store.getters.getArtworkFavourites.length < 2) {
			this.$router.push({ path: '/favourites' });
		}
	}
}
</script>