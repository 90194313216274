<template>
  <div class="event-listing">
	
		<FeaturedEvent v-if="featuredEvent" :event="featuredEvent" />
		
		<EventFilters />
		
		<Listing title="" :events="filteredEvents" />

  </div>
</template>

<script>

import FeaturedEvent from "./FeaturedEvent";
import Listing from "./Listing";
import {slugify} from "../../mixins/slugify";
import {mapState} from "vuex";
import EventFilters from "./Filters";

export default {
  components: {EventFilters, Listing, FeaturedEvent},
  props: ['featured', 'categories', 'all', 'params'],
  mixins: [slugify],
  name: 'EventsPage',
	computed: {
		...mapState({
			featuredEvent: state => state.events.featuredEvent,
			initialEvents: state => state.events.initialEvents,
			filteredEvents: state => state.events.filteredEvents
		}),
	},
  mounted() {
  	
  	this.$store.dispatch('setCategoryOptions', {
  		type: 'categories',
			items: JSON.parse(this.categories)
  	});
  	
		this.$store.dispatch('setInitialEvents', JSON.parse(this.all));
		this.$store.dispatch('setFeaturedEvent', JSON.parse(this.featured));
		this.$store.dispatch('setInitialEventFilters', JSON.parse(this.params));
		
	}
}
</script>

