<template>
  <section v-if="parsedEvents.length" class="item-carousel">
    <div class="container">

      <div class="carousel-control">

        <div class="heading-group">
          <h2 v-if="heading" id="carousel-heading">{{ heading }}</h2>

          <div class="past-current-toggle">
            <button :class="[ !showPast ? 'active' : '' ]" @click="setShowPast(false)">Show Current</button>
            <button :class="[ showPast ? 'active' : '' ]" @click="setShowPast(true)">Show Past</button>
          </div>

        </div>

        <div :class="['controls', filteredEvents.length < 4 ? 'hide-on-desktop' : '']" v-if="filteredEvents.length > 1">
          <button @click.prevent="slidePrev" v-show="!isPrevDisabled">
            <img :src="require('../../assets/images/arrows/Left.svg')" alt="Left Arrow">
          </button>
          <p class="item-count">{{ filteredEvents.length }} events</p>
          <button @click.prevent="slideNext" v-show="!isNextDisabled">
            <img :src="require('../../assets/images/arrows/Right.svg')" alt="Right Arrow">
          </button>
        </div>

      </div>

      <hooper
          :settings='{
          transition: 600,
          wheelControl: false,
          shortDrag: false,
          infiniteScroll: false,
          trimWhiteSpace: true,
          breakpoints: {
            1024: {
              itemsToShow: 3,
            },
            764: {
              itemsToShow: 2,
            },
            400: {
              itemsToShow: 1,
            },
          }
        }'
          ref="carousel"
          @slide="updateAria"
      >

        <slide v-for="event in filteredEvents" :key="event.id">
          <EventCard :event="event" />
        </slide>

      </hooper>

      <div v-if="filteredEvents.length === 0" class="no-items">
        <p>There are no {{ showPast ? 'past' : 'current' }} events right now.</p>
      </div>

    </div>
  </section>
</template>

<script>
  import { Hooper, Slide, Pagination as HooperPagination } from 'hooper';
  import 'hooper/dist/hooper.css';
  import EventCard from "./EventCard";
  import {slider} from "../../mixins/slider";

  export default {
    name: 'EventCarousel',
    props: ['events', 'heading'],
    components: {EventCard, Hooper, Slide, HooperPagination},
    mixins: [slider],
    data() {
      return {
        parsedEvents: [],
      }
    },
    mounted() {
      this.parsedEvents = JSON.parse(this.events);
      this.updateAria();
    },
    computed: {
      filteredEvents () {
        return this.parsedEvents.filter((event) => {
          return event.is_current === !this.showPast;
        });
      }
    }
  }
</script>

<style lang="scss">
@import "~hooper/dist/hooper.css";

  .hooper {
    height: auto;
  }
</style>