export var disks = {
	data() {
		return {
			disks: {
				flexible: `https://${process.env.MIX_AWS_BUCKET}.s3.ca-central-1.amazonaws.com`,
				artworks: `https://${process.env.MIX_AWS_BUCKET}.s3.ca-central-1.amazonaws.com/artworks`,
				featured: `https://${process.env.MIX_AWS_BUCKET}.s3.ca-central-1.amazonaws.com/featured`,
				sizes: ['thumb', 'medium', 'large']
			}
		}
	},
	methods: {
		getImage(fileName, disk) {
			return `${this.disks[disk]}/${fileName}`;
		},
		getExtension(fileName) {

			let filenameExtension = fileName.replace(/^.*[\\\/]/, '');
			let filename = filenameExtension.substring(0, filenameExtension.lastIndexOf('.'));
			let ext = filenameExtension.split('.').pop();

			return [filename, ext];

		},
		getResizedFlexibleImage(filename, id, size) {

			if(this.disks.sizes.includes(size)) {
				let parsedFile = this.getExtension(filename);
				return `${this.disks.flexible}/${id}/conversions/${parsedFile[0]}-${size}.${parsedFile[1]}`;
			}

			// return full size if using an invalid size name
			return `${this.disks.flexible}/${id}/${filename}`;

		},
		getResizedMainImage(filename, size, disk) {

			let diskName = disk || 'featured';

			if(this.disks.sizes.includes(size)) {
				let parsedFile = this.getExtension(filename);
				return `${this.disks[diskName]}/${parsedFile[0]}-${size}.${parsedFile[1]}`;
			}

			// return full size if using an invalid size name
			return `${this.disks[diskName]}/${filename}`;
		}
	}
}