<template>
  <div class="artwork-location">

    <div class="address">
      <img :src="getPin(parsedArtwork.artwork_type)" alt="" />
      <strong><span class="sr-only">Artwork Type: </span>{{ parsedArtwork.artwork_type }}</strong><br/>
      <span class="sr-only">Artwork Location: </span>
      {{ parsedArtwork.address }}
    </div>

    <div class="links">
      <a :href="`/?activeArtwork=${parsedArtwork.id}#map-container`" class="show-on-map">
        Show on ArtworxTO map</a> <span class="bar">|</span>
      <a :href="`https://www.google.com/maps/search/?api=1&query=${parsedArtwork.latitude},${parsedArtwork.longitude}`" target="_blank" class="show-on-google">View Artwork Location on Google Maps</a>
    </div>

  </div>
</template>

<script>
import {pins} from '../../../mixins/pins';

export default {
  name: 'ArtworkLocation',
  props: ['artwork'],
  mixins: [pins],
  computed: {
    parsedArtwork() {
      return JSON.parse(this.artwork);
    }
  }
}
</script>