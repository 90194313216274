import {helpers} from "../helpers/artworx-helpers";

const state = {

	artworks: [],
	events: [],
	showOnboarding: false

};

const mutations = {
	artworks(state, data) {
		return state.artworks = data;
	},
	events(state, data) {
		return state.events = data;
	},
	showOnboarding(state, data) {
		return state.showOnboarding = data;
	},
};

const getters = {

	getAllFavourites(state) {
		return [...state.events, ...state.artworks];
	},

	getArtworkFavourites(state) {
		return state.artworks;
	},

	getEventFavourites(state) {
		return state.events;
	}

};

const actions = {

	// Favourite Actions

	setInitialFavourites({commit}) {

		const artworkIds = helpers.getCookieValuesByName('artworks');
		const eventIds = helpers.getCookieValuesByName('events');

		commit("artworks", artworkIds);
		commit("events", eventIds);

	},

	addFavourite({commit, state}, data) {

		const {id, type} = data;

		let favourites = [...state[type]];

		const index = favourites.indexOf(id.toString());

		if(index === -1) {
			favourites = [...favourites, id.toString()];
		}

		let date = new Date();
		date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
		let expires = date.toUTCString();
		expires = '';

		document.cookie = `${type}=${favourites}; expires=${expires}; path=/`;

		commit(type, favourites);

	},

	removeFavourite({commit, state}, data) {

		const {id, type} = data;

		let favourites = [...state[type]];

		const index = favourites.indexOf(id.toString());

		if(index > -1) {
			favourites.splice(index, 1);
		}

		let date = new Date();
		date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
		let expires = date.toUTCString();

		document.cookie = `${type}=${favourites}; expires=${expires}; path=/`;

		commit(type, favourites);

	},

	maybeShowFavouriteOnboarding({commit}) {

		const seenOnboarding = helpers.checkACookieExists('seenOnboarding');

		if(!seenOnboarding) {

			commit('showOnboarding', true);

			let date = new Date();
			date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
			let expires = date.toUTCString();
			expires = '';

			document.cookie = `seenOnboarding=true; expires=${expires}; path=/`;
		}

	}

};

export default {
	state,
	mutations,
	getters,
	actions
}