<template>
	<div class="load-more no-bg">
		<button class="styled-button reversed" @click="loadMore">+ Load More</button>
	</div>
</template>

<script>
export default {
	name: "LoadMore",
	methods: {
		loadMore(event) {
			this.$emit('clicked');
		}
	}
}
</script>
