<template>
	<div class="loading">
		<img :src="require('../../assets/images/loading.svg')" alt="loading icon" />
		<p>Finding Artworks</p>
	</div>
</template>

<script>
export default {
	name: "Loading"
}
</script>