<template>
  <div v-if="tour" class="tour-card">

    <div v-if="imageURL" class="tour-card-image">
      <img :src="imageURL" :alt="`${tour.alt_text}`" />
    </div>

    <div class="tour-card-content">

      <div v-if="tour.neighbourhood" class="neighbourhood">
        <p>{{ tour.neighbourhood.name }}</p>
      </div>

      <div class="tour-card-details">

        <h3 class="tour-name"><a :href="`/tour/${tour.slug}`" :aria-label="`View ${tour.title} details`">{{ tour.title }}</a></h3>

        <p v-if="tour.sponsor" class="tour-sponsor">
          <span>Tour Sponsor: </span> {{ tour.sponsor }}
        </p>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  props: ['tour'],
  name: 'TourCard',
  computed: {
    imageURL() {

      if(!('images' in this.tour)) {
        return false;
      }

      if(!Object.keys(this.tour.images).length) {
        return false;
      }

      return this.tour.images.thumb.url;

    }
  }
}
</script>

