<template>
  <div class="container vue-gallery">

    <!-- Thumbnails -->
    <div class="thumbnail-nav">

      <button @click="prevImage" aria-label="Show Previous Image">
        <img class="arrow" src="../../assets/images/up-arrow.svg" alt="" />
      </button>

      <button
          :class="['thumbnail-button', n === currentIndex ? 'active' : '']"
          v-for="(image, n) in parsedImages"
          @click="setActive(n)"
          :aria-label="`Show ${image.custom_properties.alt_text} image`"
      >
        <img :src="getResizedFlexibleImage(image.file_name, image.id, 'thumb')" :alt="`${image.custom_properties.alt_text} thumbnail`" />
      </button>

      <button @click="nextImage" aria-label="Show Next Image">
        <img class="arrow" src="../../assets/images/down-arrow.svg" alt="" />
      </button>

    </div>

    <!-- Main -->
    <div class="main-image">

      <div class="image-container">
        <img v-if="activeImage" :src="getResizedFlexibleImage(activeImage.file_name, activeImage.id, 'large')" :alt="activeImage.custom_properties.alt_text ? activeImage.custom_properties.alt_text : activeImage.custom_properties.caption " />
      </div>

      <div class="image-info" v-if="activeImage">

        <div class="left-controls">
          <button @click="prevImage" aria-label="Show Previous Image"><img class="arrow" src="/images/left-arrow.svg" alt="" /></button>
          <p class="current-count">Image {{ currentIndex + 1 }} of {{ parsedImages.length }}</p>
        </div>

        <div class="caption" aria-label="Current Image Caption">
          <p v-if="activeImage.custom_properties.caption">{{ activeImage.custom_properties.caption }}</p>
        </div>

        <div class="right-controls">
          <button @click="nextImage" aria-label="Show Next Image"><img class="arrow" src="/images/right-arrow.svg" alt="" /></button>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import {disks} from "../../mixins/disks";

  export default {
    name: 'Gallery',
    props: ['images'],
    mixins: [disks],
    data() {
      return {
        parsedImages: [],
        currentIndex: 0
      }
    },
    computed: {
      activeImage() {
        return this.parsedImages[this.currentIndex];
      }
    },
    mounted() {
      this.parsedImages = JSON.parse(this.images);
    },
    methods: {
      nextImage() {
        this.currentIndex = this.updateArray(1);
      },
      prevImage() {
        this.currentIndex = this.updateArray(-1);
      },
      setActive(index) {
        this.currentIndex = index;
      },
      updateArray(n) {
        return ((this.currentIndex + n) % this.parsedImages.length + this.parsedImages.length) % this.parsedImages.length;
      }
    }
  }
</script>
