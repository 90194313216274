<template>
  <div class="container vue-gallery-lightbox">

    <CoolLightBox
        :items="lightboxImages"
        :index="index"
        @close="index = null">
    </CoolLightBox>

    <div class="gallery-images-wrapper">
        <button
            class="image-button"
            v-for="(image, imageIndex) in lightboxImages"
            :key="imageIndex"
            @click="index = imageIndex"
            :style="{ backgroundImage: 'url(' + image.thumbnail + ')' }"
        ></button>
    </div>

  </div>
</template>

<script>
  import {disks} from "../../mixins/disks";
  import CoolLightBox from 'vue-cool-lightbox'

  export default {
    name: 'GalleryLightbox',
    props: ['images'],
    mixins: [disks],
    components: {
      CoolLightBox
    },
    data() {
      return {
        parsedImages: [],
        index: null
      }
    },
    computed: {
      lightboxImages() {
        return this.parsedImages.map((image) => {
          return {
            src: this.getResizedFlexibleImage(image.file_name, image.id, 'large'),
            thumbnail: this.getResizedFlexibleImage(image.file_name, image.id, 'thumb'),
            title: image.custom_properties.caption
          }
        });
      }
    },
    mounted() {
      this.parsedImages = JSON.parse(this.images);
    }
  }
</script>

<style lang="scss">
  @import '~vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
</style>