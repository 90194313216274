<template>
  <div class="container tour-listing">

    <FiltersWrapper
        @reset="resetFilters"
        label="Filter Tours"
    >

      <SelectAutocompleteFilter
          heading="Filter Tours by Neighbourhood"
          :options="getOptions('neighbourhoods')"
          :filter-type="`neighbourhoods`"
          add-dispatch-action="setNeighbourhoodFilter"
          remove-dispatch-action="removeNeighbourhoodFilter"
          state-scope="tours"
          :button-text="`Submit`"
      />

    </FiltersWrapper>

    <div class="tour-filter-message-container">

      <div class="filter-message-container">
        <p class="filter-message" role="status" aria-live="polite" aria-atomic="true">
          Found {{ filteredTours.length }} tours</p>
      </div>

    </div>

    <div class="tour-cards">
      <TourCard v-if="filteredTours" v-for="tour in filteredTours" :tour="tour" :key="tour.id" />
      <p v-if="!filteredTours.length">There are no tours matching that filter</p>
    </div>

  </div>
</template>

<script>
import TourCard from "./TourCard";
import {mapState} from "vuex";
import SelectAutocompleteFilter from "../global/filters/SelectAutocompleteFilter";
import FiltersWrapper from "../global/filters/FiltersWrapper";

export default {
  name: "TourListing",
  components: {FiltersWrapper, SelectAutocompleteFilter, TourCard},
  props: ['neighbourhoods', 'tours', 'params'],
  computed: {
    ...mapState({
      initialTours: state => state.tours.initialTours,
      filteredTours: state => state.tours.filteredTours,
      options: state => state.tours.options
    }),
  },
  mounted() {

    this.$store.dispatch('setNeighbourhoodOptions', {
      type: 'neighbourhoods',
      items: JSON.parse(this.neighbourhoods)
    });

    this.$store.dispatch('setInitialTours', JSON.parse(this.tours));
    this.$store.dispatch('setInitialTourFilters', JSON.parse(this.params));

  },
  methods: {
    getOptions(type) {
      return this.options[type];
    },
    resetFilters() {
      this.$store.dispatch("clearTourFilters");
    }
  },
}
</script>