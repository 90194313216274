export var slider = {
	data() {
		return {
			isNextDisabled: false,
			isPrevDisabled: true,
			showPast: false,
			currentIndex: 0
		}
	},
	methods: {
		updateAria() {
			this.$nextTick(() => {
				
				if (this.$refs.carousel) {
					
					let slides = this.$refs.carousel.$children;
					
					slides.forEach((slide) => {
						slide.$el.removeAttribute('aria-hidden')
					});
					
				}
				
			});
		},
		setShowPast(val) {
			this.showPast = val;
			this.$refs.carousel.slideTo(0);
			this.$refs.carousel.update();
			
			this.$nextTick(() => {
				this.setNavStates();
			});
			
		},
		setIsNextDisabled() {
			this.isNextDisabled = this.$refs.carousel.currentSlide === this.$refs.carousel.slidesCount - Math.min(this.$refs.carousel.config.itemsToShow, this.$refs.carousel.slidesCount);
		},
		setIsPrevDisabled() {
			this.isPrevDisabled = this.$refs.carousel.currentSlide === 0;
		},
		setNavStates() {
			this.setIsNextDisabled();
			this.setIsPrevDisabled();
			this.currentIndex = this.$refs.carousel.currentSlide;
		},
		slidePrev() {
			this.$refs.carousel.slidePrev();
			this.setNavStates();
		},
		slideNext() {
			this.$refs.carousel.slideNext();
			this.setNavStates();
		},
	}
}