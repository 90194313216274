<template>
  <div class="checkbox-radio-filter">

		<FilterContainer :label="heading" :filter-type="filterType" :group-type="`multi`">
			<div class="checkbox" v-for="option in options" :key="option">
				<input v-model="filter" type="checkbox" @change="sendToOracle(option)" :value="option" :id="slugify(option)">
				<label :for="slugify(option)">
					<img height="25px" v-if="showPins" :src="getPin(option)" alt="" />
					<div class="diamond-icon" :style="{background: getDiamondColour(option)}" v-if="showDiamonds"></div> {{ option }}
				</label>
			</div>
		</FilterContainer>
		
  </div>
</template>

<script>
import ClearButton from "../../artworks/buttons/ClearButton";
import {pins} from "../../../mixins/pins";
import {slugify} from "../../../mixins/slugify";
import FilterContainer from "./FilterContainer";
import {diamonds} from "../../../mixins/diamonds";

export default {
  name: "MultiSelectFilter",
  components: {FilterContainer, ClearButton},
  props: ['filterType', 'options', 'heading', 'showPins', 'dispatchAction', 'stateName', 'stateScope', 'showDiamonds'],
  mixins: [pins, slugify, diamonds],
  computed: {
    filter: {
      set(value) {
        this.$store.dispatch(this.dispatchAction, {type: this.filterType, data: value});
      },
      get() {
        let type = this.stateName ? this.stateName : this.filterType;
        return this.$store.state[this.stateScope][type];
      }
    }
  },
  methods: {
    sendToOracle(option) {

      if(this.filter.includes(option) && window.dcsMultiTrack) {
        window.dcsMultiTrack('filter',option,'category',this.filterType);
      }

    }
  }
}
</script>